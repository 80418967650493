var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', {
    staticClass: "py-20 py-md-50"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "d-none d-sm-block",
    attrs: {
      "max-width": "156",
      "width": "100%",
      "src": "/images/logo-dark.svg"
    }
  }), _c('v-img', {
    staticClass: "d-sm-none",
    attrs: {
      "max-width": "100",
      "width": "100%",
      "src": "/images/logo-dark.svg"
    }
  })], 1), _c('v-col', [_c('div', [_c('ul', {
    staticClass: "footer-info"
  }, [_c('li', [_vm._v("Corporate name : Dentium Co., Ltd. Representaive : Suh Seung Woo / 76, Changryong-daero 256beon-gil, Yeongtong-gu, Suwon-si, Gyeonggi-do, 1F")]), _c('li', {
    staticClass: "clear"
  }, [_vm._v("Business registrarion number : 123-81-57732 Mail Order Sales Report Number : 135111-0052892")]), _c('li', {
    staticClass: "clear"
  }, [_vm._v("COPYRIGHTⓒ 2023 DENTIUM. ALL RIGHTS RESERVED")])])])]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('u-scroll-top', {
    staticClass: "mt-auto ml-auto"
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }