var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.className == 'sub-tab-wrap' ? _c('div', {
    class: 'tab-wrap ' + _vm.className
  }, [_vm._l(_vm.gnb, function (item) {
    return [item.title == _vm.title ? _c('ul', {
      key: item.title,
      staticClass: "tab tab--inline tab--underline-inline justify-center"
    }, _vm._l(item.children, function (child) {
      return _c('li', {
        key: child.title,
        staticClass: "tab__li",
        class: {
          active: _vm.tabActive == child.title
        }
      }, [child.href ? _c('a', {
        staticClass: "tab__btn",
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])]) : _c('router-link', {
        staticClass: "tab__btn",
        attrs: {
          "to": child.to
        }
      }, [_c('span', [_vm._v(_vm._s(child.title))])])], 1);
    }), 0) : _vm._e()];
  })], 2) : _vm.className == 'gnb' ? _c('ul', {
    class: _vm.className
  }, [_vm._l(_vm.gnb, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [item.href ? _c('a', {
      class: _vm.className + '__link',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _c('router-link', {
      class: _vm.className + '__link',
      attrs: {
        "to": item.to
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item.title,
      class: _vm.className + '__li'
    }, [_c('div', {
      class: _vm.className + '__link gnb__link--toggle d-xl-none'
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('router-link', {
      class: _vm.className + '__link d-none d-xl-flex',
      attrs: {
        "to": item.to
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), item.href ? _c('a', {
      class: _vm.className + '__link',
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _vm._e(), _c('div', {
      class: _vm.className + '__sub'
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_vm._l(item.children, function (child) {
      return [!child.children ? _c('v-col', {
        key: child.title,
        attrs: {
          "cols": _vm.isDesktop ? 3 : 12
        }
      }, [_c('div', {
        class: _vm.className + '__sub-inner',
        style: child !== null && child !== void 0 && child.color ? `background-color: ${child === null || child === void 0 ? void 0 : child.color}` : undefined
      }, [child.href ? _c('a', {
        class: _vm.className + '-sub__link',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        class: _vm.className + '-sub__link',
        attrs: {
          "to": child.to
        }
      }, [_vm._v(_vm._s(child.title))])], 1)]) : _c('v-col', {
        key: child.title,
        attrs: {
          "cols": _vm.isDesktop ? 3 : 12
        }
      }, [_c('div', {
        class: _vm.className + '__sub-inner',
        style: child !== null && child !== void 0 && child.color ? `background-color: ${child === null || child === void 0 ? void 0 : child.color}` : undefined
      }, [_c('v-row', {
        attrs: {
          "no-gutters": "",
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [child.href ? _c('a', {
        class: _vm.className + '-sub__link',
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_c('h2', [_vm._v(_vm._s(child.title))])]) : _c('router-link', {
        class: _vm.className + '-sub__link',
        attrs: {
          "to": child.to
        }
      }, [_c('h2', [_vm._v(_vm._s(child.title))])])], 1)], 1), _c('v-expand-transition', [_c('ul', {
        class: _vm.className + '__sub2'
      }, [_vm._l(child.children, function (grandChild) {
        return [!grandChild.children ? _c('li', {
          key: grandChild.title
        }, [grandChild.href ? _c('a', {
          class: _vm.className + '-sub2__link text-truncate',
          attrs: {
            "href": grandChild.href,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(grandChild.title))]) : _c('router-link', {
          class: _vm.className + '-sub2__link text-truncate',
          attrs: {
            "to": grandChild.to
          }
        }, [_vm._v(_vm._s(grandChild.title))])], 1) : _vm._e()];
      })], 2)])], 1)])];
    })], 2)], 1)], 1)];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }