import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/Guide.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
        props: true,
    },
    // {
    //     // 회원가입 폼
    //     path: "/join/type",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
    //     props: true,
    // },
    {
        // 회원가입 폼
        path: "/join/form",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 회원가입 완료
        path: "/join/complete",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
        props: true,
    },
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    {
        // 회원 정보 상세
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 회원 정보 상세
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 회원 정보 변경
        path: "/mypage/myinfo/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 회원 정보 상세
        path: "/mypage/product-inquiries",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ProductInquiryList.vue"),
        props: true,
    },
    {
        // 회원 정보 상세
        path: "/mypage/product-inquiries/:_form",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ProductInquiryView.vue"),
        props: true,
    },
    {
        // 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 회원 탈퇴
        path: "/mypage/leave/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 결제내역
        path: "/mypage/payments",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/PaymentHistoryPage.vue"),
        props: true,
    },
    {
        // 인보이스
        path: "/mypage/invoice",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/InvoicePage.vue"),
        props: true,
    },
    {
        // 위시 리스트
        path: "/mypage/academy-favorites",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/FavoriteListPage.vue"),
        props: true,
    },
    {
        // 쿠폰
        path: "/mypage/academy-coupons",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/CouponListPage.vue"),
        props: true,
    },
    {
        // About - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // About - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 서비스이용약관
        path: "/service-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/ServicePolicyPage.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // Dentium Global
    ////////////////////////////////////////////////////////////////////////////
    {
        // Dentium Global - Global Network
        path: "/global/network",
        component: () => import(/* webpackChunkName: "global" */ "../pages/client/sub/global/GlobalNetworkPage.vue"),
        props: true,
    },
    {
        // Dentium Global - Global Network
        path: "/global/network/:tab",
        component: () => import(/* webpackChunkName: "global" */ "../pages/client/sub/global/GlobalNetworkPage.vue"),
        props: true,
    },
    {
        // Dentium Global - Global SNS
        path: "/global/sns",
        component: () => import(/* webpackChunkName: "global" */ "../pages/client/sub/global/GlobalSnsPage.vue"),
        props: true,
    },
    {
        // Dentium Global - Global SNS
        path: "/global/sns/:tab",
        component: () => import(/* webpackChunkName: "global" */ "../pages/client/sub/global/GlobalSnsPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // Dentium Product
    ////////////////////////////////////////////////////////////////////////////
    {
        // Dentium Product - List
        path: "/categories",
        component: () => import(/* webpackChunkName: "product" */ "../pages/client/sub/categories/CategoryViewPage.vue"),
        props: true,
    },
    {
        // Dentium Product - List
        path: "/categories/:code",
        component: () => import(/* webpackChunkName: "product" */ "../pages/client/sub/categories/CategoryViewPage.vue"),
        props: true,
    },
    {
        // Dentium Product - List
        path: "/products",
        component: () => import(/* webpackChunkName: "product" */ "../pages/client/sub/products/ProductListPage.vue"),
        props: true,
    },
    {
        // Dentium Product - View
        path: "/products/:_product",
        component: () => import(/* webpackChunkName: "product" */ "../pages/client/sub/products/ProductViewPage.vue"),
        props: true,
    },
    {
        // Dentium Product - View - Inquiry
        path: "/products/:_product/inquiry",
        component: () => import(/* webpackChunkName: "product" */ "../pages/client/sub/products/ProductInquiryPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // Download
    ////////////////////////////////////////////////////////////////////////////
    {
        // Download - Catalog
        path: "/download-center/catalogs",
        component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/CatalogListPage.vue"),
        props: { code: null },
    },
    {
        // Download - Catalog
        path: "/download-center/abstracts",
        component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/CatalogListPage.vue"),
        props: { code: "abstracts" },
    },
    // {
    //     // Download - Catalog
    //     path: "/download-center/catalogs_test",
    //     component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/CatalogListPage_.vue"),
    //     props: true,
    // },
    {
        // Download - Digital Guide S/W
        path: "/download-center/guides",
        component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/DigitalGuidePage.vue"),
        props: true,
    },
    {
        // Download - Digital Guide S/W
        path: "/download-center/guides/:_software",
        component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/DigitalGuidePage.vue"),
        props: true,
    },
    {
        // Download - CI
        path: "/download-center/ci",
        component: () => import(/* webpackChunkName: "download-center" */ "../pages/client/sub/download-center/CiPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // Dentium Minimalism in Dentium
    ////////////////////////////////////////////////////////////////////////////
    {
        // Dentium Minimalism in Dentium Board
        path: "/minimalism/",
        component: () => import(/* webpackChunkName: "minimalism" */ "../pages/client/sub/minimalism/MinimalismBoardPage.vue"),
        props: true,
    },
    {
        // Dentium Minimalism in Dentium Board
        path: "/minimalism/:code1",
        component: () => import(/* webpackChunkName: "minimalism" */ "../pages/client/sub/minimalism/MinimalismBoardPage.vue"),
        props: true,
    },
    {
        // Dentium Minimalism in Dentium Board
        path: "/minimalism/:code1/:code2",
        component: () => import(/* webpackChunkName: "minimalism" */ "../pages/client/sub/minimalism/MinimalismBoardPage.vue"),
        props: true,
        children: [...BoardRoutes],
    },

    ////////////////////////////////////////////////////////////////////////////
    // Dentium Story
    ////////////////////////////////////////////////////////////////////////////
    {
        // Dentium Story - Dentium Values - Overview / Brand Story
        path: "/story/values/overview",
        component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesOverviewPage.vue"),
        props: true,
    },
    // {
    //     // Dentium Story - Dentium Values - History
    //     path: "/story/values/history",
    //     component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesHistoryPage.vue"),
    //     props: true,
    // },
    // {
    //     // Dentium Story - Dentium Values - Vision - Trust
    //     path: "/story/values/vision/trust",
    //     component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesVisionTrustPage.vue"),
    //     props: true,
    // },
    {
        // Dentium Story - Dentium Values - Vision - Challenge
        path: "/story/values/vision/challenge",
        component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesVisionChallengePage.vue"),
        props: true,
    },
    {
        // Dentium Story - Dentium Values - R&D
        path: "/story/values/rnd",
        component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesRndPage.vue"),
        props: true,
    },
    {
        // Dentium Story - Dentium Values - Live Surgery
        path: "/story/values/live",
        component: () => import(/* webpackChunkName: "story" */ "../pages/client/sub/story/ValuesLiveSurgeryPage.vue"),
        props: true,
    },
    {
        // Dentium Story - News
        path: "/story/news",
        component: () => import(/* webpackChunkName: "news" */ "../pages/client/sub/story/NewsListPage.vue"),
        props: true,
    },
    {
        // Dentium Story - News - View
        path: "/story/news/:_seminar",
        component: () => import(/* webpackChunkName: "news" */ "../pages/client/sub/story/NewsViewPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // Contact Us
    ////////////////////////////////////////////////////////////////////////////
    // {
    //     // Contact Us - Gate
    //     path: "/contact",
    //     component: () => import(/* webpackChunkName: "contact" */ "../pages/client/sub/contact/ContactUsGate.vue"),
    //     props: true,
    // },
    {
        // Contact Us - Form
        path: "/contact",
        component: () => import(/* webpackChunkName: "contact" */ "../pages/client/sub/contact/ContactUsForm.vue"),
        props: true,
    },
    // {
    //     // Contact Us - Board
    //     path: "/contact/board",
    //     component: () => import(/* webpackChunkName: "contact" */ "../pages/client/sub/contact/ContactUsBoard.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },

    // {
    //     // Academy - Main
    //     path: "/academy",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/AcademyMainPage.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - Live
    //     path: "/academy/live",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/LiveListPage.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - On-Demand - List
    //     path: "/academy/on-demand",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandList.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - On-Demand - View
    //     path: "/academy/on-demand/:_lecture",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandView.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - On-Demand - Purchase
    //     path: "/academy/on-demand/:_lecture/purchase",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandPurchase.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - On-Demand - Result
    //     path: "/academy/on-demand/:_lecture/purchase/:_order",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandPurchase.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - On-Demand - View
    //     path: "/academy/on-demand/:_lecture/:tab",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandView.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    // {
    //     // Academy - Speaker
    //     path: "/academy/speaker",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/SpeakerListPage.vue"),
    //     props: true,
    // },
    // {
    //     // Academy - Speaker - Lectures
    //     path: "/academy/speaker/:_lecturer/lectures",
    //     component: () => import(/* webpackChunkName: "academy" */ "../pages/client/sub/academy/OnDemandList.vue"),
    //     props: true,
    // },
    // {
    //     // Digital Center - Library
    //     path: "/digital-center/library",
    //     component: () => import(/* webpackChunkName: "digital-center" */ "../pages/client/sub/digital-center/LibraryPage.vue"),
    //     children: [...BoardRoutes],
    //     props: true,
    // },
    {
        // Search - List
        path: "/search",
        component: () => import(/* webpackChunkName: "products" */ "../pages/client/sub/products/ProductListPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/plugins/easy/pay/result",
        component: () => import("../pages/plugins/kicc/pay/EasyPayResult.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 슬라이드
        path: "/console/main-visual-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainVisualBanners.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 상품진열
        path: "/console/main-product-display",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainProductDisplay.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 상품진열
        path: "/console/main-product-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainProductBanners.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인 상품진열
        path: "/console/main-video",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainVideoPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 지사 목록
        path: "/console/branches",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/branches/BranchListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 지사 지역배정
        path: "/console/branch-assignment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/branches/BranchListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 지역 목록
        path: "/console/zones",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/zones/ZonesPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 지역 목록
        path: "/console/zone-order",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/zones/ZonesPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 지역 계층
        path: "/console/zone-tree",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/zones/ZonesPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - SNS
        path: "/console/sns/accounts",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/sns/SnsAccountList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 상단 배너
        path: "/console/shop-top-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ShopTopBanners.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 목록
        path: "/console/shop-products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 상세
        path: "/console/shop-products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductViewPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 상세
        path: "/console/shop-products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/ProductViewPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 카테고리
        path: "/console/shop-categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CategoryListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 카탈로그
        path: "/console/shop-catalogues",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CatalogueListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 카탈로그
        path: "/console/shop-abstracts",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/CatalogueListPage.vue"),
        props: { code: "abstracts" },
        scope: ["console"],
    },
    {
        // 관리자 - 프로덕트 도큐먼트
        path: "/console/shop-documents",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/DocumentListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 상단 배너
        path: "/console/academy-top-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/AcademyTopBanners.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 라이브
        path: "/console/academy-lives",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/LiveListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 세미나
        path: "/console/academy-seminars",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/SeminarListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 강연
        path: "/console/academy-lectures",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/LectureListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 쿠폰
        path: "/console/academy-coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/CouponListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 쿠폰 발급
        path: "/console/academy-coupons/issue",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/CouponListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 아카데미 강연자
        path: "/console/academy-lecturers",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/academy/LecturerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 다운로드센터
        path: "/console/membership-softwares",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/membership/SoftwareListPage.vue"),
        props: true,
    },
    {
        // 관리자 - 다운로드센터
        path: "/console/membership-softwares/:_software",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/membership/SoftwareListPage.vue"),
        props: true,
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/general-inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/GeneralInquiryList.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/smtp",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SmtpView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        }

        if (scrollTo != undefined) {
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
        } else {
            return { x: 0, y: 0 }; // Go to the top of the page if no hash
        }
    },
});

router.beforeEach((to, from, next) => {
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";
    axios.defaults.headers.common["Accept-Language"] = i18n.locale;

    next();
});

export default router;
