<template>
    <section class="main-section">
        <div v-if="this.$slots['title']" class="tit-wrap align-center mb-lg-60 overflow-hidden">
            <h2 class="tit text-center" data-aos="fade-up"><slot name="title" /></h2>
            <slot name="title-actions" />
        </div>
        <slot />
    </section>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.main-section {
    padding: 60px 0;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    &--visual {
        padding: 0 !important;
    }
}
@media (min-width: 576px) {
    .main-section {
        &--visual {
            padding: 0 12.5% !important;
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding: 80px 0;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-section {
        padding: 140px 0;
        &--visual {
            padding: 0 12.5% 0 0 !important;
        }
    }
}
</style>
