export const USER_TYPES = {
    PERSON: { text: "일반", value: "PERSON" },
    BRANCH: { text: "지사", value: "BRANCH" },
};

export const USER_NOTIFY_METHODS = {
    SMS: { text: "SMS(문자메시지)", value: "user.notification:SMS" },
    EMAIL: { text: "이메일", value: "user.notification:EMAIL" },
    PHONE: { text: "전화", value: "user.notification:PHONE" },
};

export const PERSON_OCCUPATIONS = {
    ORTHODONTIST: { value: "ORTHODONTIST", text: "Orthodontist" },
    DENTIST: { value: "DENTIST", text: "Dentist" },
    GENERAL_DENTIST: { value: "GENERAL_DENTIST", text: "General Dentist" },
    DENTAL_TECHNICIAN: { value: "DENTAL_TECHNICIAN", text: "Dental Technician" },
    DENTAL_HYGIENIST: { value: "DENTAL_HYGIENIST", text: "Dental Hygienist (DH/ZMF)" },
    DENTAL_ADMINISTRATIVE_ASSISTANT: { value: "DENTAL_ADMINISTRATIVE_ASSISTANT", text: "Dental Administrative Assistant (ZMV)" },
    DENTAL_ASSISTANT: { value: "DENTAL_ASSISTANT", text: "Dental Assistant (ZFA)" },
    DENTAL_PROPHYLAXIS: { value: "DENTAL_PROPHYLAXIS", text: "Dental Prophylaxis (ZMP)" },
    EMPLOYEE: { value: "EMPLOYEE", text: "Employee" },
    DENTURIST: { value: "DENTURIST", text: "Denturist" },
    CLINICAL_DENTURE_SPECIALIST: { value: "CLINICAL_DENTURE_SPECIALIST", text: "Clinical Denture Specialist" },
    STUDENT: { value: "STUDENT", text: "Student" },
    DENTIUM_EMPLOYEE: { value: "DENTIUM_EMPLOYEE", text: "Dentium Employee" },
    ETC: { value: "ETC", text: "ETC" },
};

export const PERSON_TITLES = {
    DOCTOR: { value: "DOCTOR", text: "Dr." },
    PROFESSOR: { value: "PROFESSOR", text: "Prof." },
    MISTER: { value: "MISTER", text: "Mr." },
    MISSIS: { value: "MISSIS", text: "Mrs." },
    MISS: { value: "MISS", text: "Ms." },
};

export const BOARD_YOUTUBE_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "디테일 미술사(작품탐구)" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_REPORT_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "양식 미술사" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_FORMS_CATEGORIES = {
    AUCTION_FORMS: { value: "AUCTION_FORMS", text: "경매 서식" },
    AUCTION_INFSO: { value: "AUCTION_INFOS", text: "경매 정보" },
    ART_NARRATIVE: { value: "ART_NARRATIVE", text: "미술 이야기" },
    CONSULTATIONS: { value: "CONSULTATIONS", text: "아트 컨설팅" },
};

export const FOOTER_SNS_TYPES = {
    twitter: { text: "트위터", value: "twitter" },
    facebook: { text: "페이스북", value: "facebook" },
    instagram: { text: "인스타그램", value: "instagram" },
};

export const ZONE_TYPES = {
    CONTINENT: { text: "대륙", value: "CONTINENT" },
    COUNTRY: { text: "국가", value: "COUNTRY" },
    REGION: { text: "지역", value: "REGION" },
};

export const BRANCH_GROUPS = {
    africa: { value: "africa", text: "Africa", color: "#ff8d39" },
    "asia-pacific": { value: "asia-pacific", text: "Asia-Pacific", color: "#a2c438" },
    america: { value: "america", text: "America", color: "#ff5b5b" },
    europe: { value: "europe", text: "Europe", color: "#00a0e9" },
    "middle-east": { value: "middle-east", text: "Middle East", color: "#aa89bd" },
    "russia-cis": { value: "russia-cis", text: "Russia/CIS", color: "#e782d0" },
};

export const ACADEMY_LIVE_STATES = {
    inProgress: { text: "In Progress", value: "inProgress" },
    scheduled: { text: "Scheduled", value: "scheduled" },
    finished: { text: "Finished", value: "finished" },
};

export const ACADEMY_LECTURE_CATEGORIES = {
    sinus: { text: "Sinus", value: "sinus" },
    Edentulous: { text: "Edentulous", value: "Edentulous" },
    gbr: { text: "GBR", value: "gbr" },
    implant: { text: "Implant", value: "implant" },
    perio: { text: "Perio", value: "perio" },
    prosthetic: { text: "Prosthetic", value: "prosthetic" },
    etc: { text: "ETC", value: "etc" },
};

export const STORY_NEWS_CATEGORIES = {
    seminar: { value: "seminar", text: "Seminar" },
    forum: { value: "forum", text: "Forum" },
    exhibition: { value: "exhibition", text: "Exhibition" },
    symposium: { value: "symposium", text: "Symposium" },
};

export const ACADEMY_FAVORITE_TYPES = {
    LECTURE: { text: "On-Demand", value: "LECTURE" },
};

export const ACADEMY_COUPON_TYPES = {
    LECTURE_AMOUNT: { text: "ON-Demand amount coupon", value: "LECTURE_AMOUNT" },
};

export const ACADEMY_COUPON_STATUSES = {
    APPLIED: { value: "APPLIED", text: "사용완료" },
    EXPIRED: { value: "EXPIRED", text: "사용만료" },
    REVOKED: { value: "REVOKED", text: "발급취소" },
    WAITING: { value: "WAITING", text: "사용대기" },
    DISABLED: { value: "DISABLED", text: "사용정지" },
    APPLICABLE: { value: "APPLICABLE", text: "사용가능" },
};

export const ORDER_TYPES = {
    LECTURE: { text: "On-Demand", value: "LECTURE" },
};

export const COMPANY_WEBSITE_TYPES = {
    INSTAGRAM: { value: "INSTAGRAM", text: "Instagram", icon: "icon-insta" },
    FACEBOOK: { value: "FACEBOOK", text: "Facebook", icon: "icon-f" },
    HOMEPAGE: { value: "HOMEPAGE", text: "Homepage", icon: "icon-home" },
    OTHERS: { value: "OTHERS", text: "Others", icon: "icon-expand-arrows-white" },
};

export const SNS_TYPES = {
    INSTAGRAM: { value: "INSTAGRAM", text: "인스타그램" },
};

export const PRODUCT_TYPES = {
    NORMAL: { value: "NORMAL", text: "일반" },
    KIT: { value: "KIT", text: "키트" },
};

export const FORM_STATES = {
    INQUIRED: { value: "INQUIRED", text: "Inquired" },
    ANSWERED: { value: "ANSWERED", text: "Answered" },
};
