var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.searchValue);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.$emit('search');
      }
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, 'v-text-field', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }