import axios from "@/plugins/axios";

let url = "/api/v1/me/lectures";
let recordsUrl = (data) => "/api/v1/me/lectures/${_lecture}/records".interpolate(data);

export default {
    get(data) {
        return axios.get(`${url}/${data._lecture}`).then((result) => result.data);
    },
    records: {
        post(data) {
            return axios.post(recordsUrl(data), data).then((result) => result.data);
        },
    },
};
