import axios from "@/plugins/axios";

import contact from "./contact";

let url = "/api/v1/users";

export default {
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    postBusinessRegistration(data, file) {
        var formData = new FormData();
        formData.append("businessRegistration", file);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/businessRegistration`, formData, { headers }).then((result) => result.data);
    },
    postLogo(data, logo) {
        var formData = new FormData();
        formData.append("logo", logo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/logo`, formData, { headers }).then((result) => result.data);
    },
    postPhoto(data, photo) {
        var formData = new FormData();
        formData.append("photo", photo);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/photo`, formData, { headers }).then((result) => result.data);
    },

    certify: {
        post(data) {
            return axios.post(`${url}/certify`, data).then((result) => result.data);
        },
    },
    recovery: {
        post(data) {
            return axios.post(`${url}/recovery`, data).then((result) => result.data);
        },
    },

    contact,
};
