import lecturers from "./lecturers";
import lectures from "./lectures";
import lives from "./lives";
import seminars from "./seminars";

export default {
    lecturers,
    lectures,
    lives,
    seminars,
};
