var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.fixedScroll,
      expression: "fixedScroll"
    }],
    staticClass: "header header--fixed"
  }, [_c('div', {
    staticClass: "header__body"
  }, [_c('v-container', [_c('h1', {
    staticClass: "header__logo"
  }, [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("Dentium")])])], 1), _c('div', {
    staticClass: "header__contents"
  }, [_c('div', {
    staticClass: "header__gnb"
  }, [_c('client-gnb', {
    attrs: {
      "className": "gnb"
    }
  })], 1), _c('div', {
    staticClass: "header__right"
  }, [_c('user-menu'), _c('div', {
    staticClass: "hamburger-btn-pc ml-lg-30"
  }, [_c('span')])], 1), _vm.accessToken ? _c('div', {
    staticClass: "d-xl-none text-right mt-20",
    staticStyle: {
      "order": "3"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey-e6"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Logout")])])], 1) : _vm._e()])])], 1), _vm._m(0), _c('side-gnb')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hamburger-btn"
  }, [_c('span')]);

}]

export { render, staticRenderFns }