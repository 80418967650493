var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "network-map"
  }, [_vm._m(0), _vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value,
      color = _ref.color,
      countries = _ref.countries;
    return [_c('v-menu', {
      key: value,
      attrs: {
        "open-on-hover": "",
        "offset-y": "",
        "content-class": "menu-arrow network-map__menu"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            class: `network-map__btn network-map__${value}`,
            attrs: {
              "rounded": ""
            }
          }, 'v-btn', Object.assign({}, attrs, {
            color
          }), false), on), [_c('span', {
            staticClass: "white--text"
          }, [_vm._v(_vm._s(text))])])];
        }
      }], null, true)
    }, [_c('div', {
      staticClass: "network-map__menu-inner"
    }, [_c('ul', {
      staticClass: "country-items"
    }, [_vm._l(countries, function (_ref3, index) {
      var name = _ref3.name,
        flag = _ref3.flag;
      return [_c('li', {
        key: `country-item-${index}-${name}`,
        staticClass: "country-item"
      }, [_c('router-link', {
        staticClass: "country-item__link",
        attrs: {
          "to": {
            path: `/global/network/${value}`,
            query: {
              flag
            }
          }
        }
      }, [_c('span', {
        staticClass: "country-item__flag"
      }, [_vm._v(_vm._s(flag))]), _c('span', [_vm._v(_vm._s(name))])])], 1)];
    })], 2)])])];
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "network-map__img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/story/global/network-map.png",
      "alt": "이미지"
    }
  })]);

}]

export { render, staticRenderFns }