<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }"></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer></main-footer>
        <!-- E: FOOTER -->
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    props: {
        isMain: { type: Boolean, default: true },
        disableScrollTop: { type: Boolean, default: false },
    },
    components: {
        MainHeader,
        MainFooter,
    },
    destroyed() {
        // window.removeEventListener("copy", this.preventCopy);
        // window.removeEventListener("contextmenu", this.preventCopy);
        // window.removeEventListener("selectstart", this.preventDefault);
        // window.removeEventListener("dragstart", this.preventDefault);
    },
    mounted() {
        this.init();
        // window.addEventListener("copy", this.preventCopy);
        // window.addEventListener("contextmenu", this.preventCopy);
        // window.addEventListener("selectstart", this.preventDefault);
        // window.addEventListener("dragstart", this.preventDefault);
    },
    methods: {
        async init() {
            var HTML = document.querySelector("html");
            HTML.classList.remove("scroll-smooth");

            setTimeout(() => {
                AOS.init({
                    once: true,
                    duration: 700,
                    throttleDelay: 100,
                    easing: "ease-in-out",
                    anchorPlacement: "top-center",
                });
            }, 100);
        },

        // preventCopy(event) {
        //     alert("보안 정책에 의해 복사를 허용하지 않습니다.");
        //     event.preventDefault();
        // },
        // preventDefault(event) {
        //     event.preventDefault();
        // },
    },
};

</script>
