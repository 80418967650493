var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-section', {
    staticClass: "main-section--visual"
  }, [_c('main-visual', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-visual-banners-desktop"
    }
  }), _c('main-visual', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-visual-banners-mobile"
    }
  })], 1), _c('main-section', {
    staticStyle: {
      "background-image": "url('/images/main/minimalism-bg.jpg')"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Minimalism")];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('main-banners', {
    attrs: {
      "code": "main-product-banners"
    }
  })], 1)], 1), _c('main-section', {
    staticClass: "pt-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Global")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "tab-wrap mt-n30 overflow-hidden"
  }, [_c('ul', {
    staticClass: "tab tab--inline tab--underline-inline justify-center",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('li', {
    staticClass: "tab__li active"
  }, [_c('router-link', {
    staticClass: "tab__btn",
    attrs: {
      "to": "/global/network"
    }
  }, [_c('span', [_vm._v("Network")])])], 1), _c('li', {
    staticClass: "tab__li"
  }, [_c('router-link', {
    staticClass: "tab__btn",
    attrs: {
      "to": "/global/sns"
    }
  }, [_c('span', [_vm._v("News")])])], 1)])]), _c('v-container', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('global-network-showcase')], 1)], 1), _c('main-section', {
    staticStyle: {
      "background-image": "url('/images/main/clinical-bg.jpg')"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Clinical Case")];
      },
      proxy: true
    }])
  }, [_c('main-video')], 1), _c('main-section', {
    staticStyle: {
      "background-image": "url('/images/main/event-bg.jpg')"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Event")];
      },
      proxy: true
    }])
  }, [_c('v-container', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('main-events')], 1)], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }