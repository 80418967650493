<template>
    <div class="main-video-wrap">
        <div class="main-video-wrap__inner">
            <div class="main-video">
                <swiper :options="swiperOptions" ref="swiper">
                    <!-- <swiper-slide><div class="main-video__inner"></div></swiper-slide> -->
                    <swiper-slide v-for="{ videoId } in items" :key="videoId">
                        <div class="main-video__inner">
                            <div class="video-responsive">
                                <youtube v-bind="{ videoId }" fitParent @ready="count += 1" />
                            </div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-control" slot="pagination" @click.prevent>
                        <button class="swiper-button-prev" slot="button-prev"></button>
                        <button class="swiper-button-next" slot="button-next"></button>
                    </div>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { initSetting } from "@/assets/variables";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {},
    data() {
        return {
            setting: initSetting(),

            count: 0,

            swiperOptions: {
                init: false,

                allowTouchMove: false,
                centeredSlides: true,
                spaceBetween: 30,
                loopAdditionalSlides: 2,
                // slidesPerView: 2,
                loop: true,
                speed: 1200,
                navigation: {
                    nextEl: ".main-video .swiper-button-next",
                    prevEl: ".main-video .swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        spaceBetween: 60,
                    },
                    // 1024: {
                    //     slidesPerView: 3,
                    // },
                },
            },
        };
    },
    computed: {
        items() {
            return [...this.setting.main.videos];
        },
        toInitiate() {
            if (this.items.length > 0 && this.items.length == this.count) return true;
            else return false;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        toInitiate(bool) {
            if (bool) {
                this.swiperOptions.init = true;
                this.$nextTick(() => this.$refs["swiper"].initSwiper());
            }
        },
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = initSetting(setting);
        },
    },
};
</script>

<style lang="scss" scoped>
.main-video-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &__inner {
        width: 100%;
        padding: 0 30px;
        overflow: hidden;
    }
}
.main-video {
    width: 100%;
    margin: auto;
    &__inner {
        position: relative;
        width: 100%;
        transform: scale(0.9);
        -webkit-transition: transform ease-out 0.2s;
        -ms-transition: transform ease-out 0.2s;
        transition: transform ease-out 0.2s;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 1;
            visibility: visible;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
    }
    ::v-deep {
        .swiper-container {
            overflow: visible;
        }
        .swiper-slide {
            &-active {
                .main-video__inner {
                    transform: scale(1);
                    &::before {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            width: 30px;
            height: 30px;
            color: rgba(0, 0, 0, 0.54);
            font: normal normal normal 24px/1 "Material Design Icons";
            margin-top: 0;
            &::after {
                font-size: 20px;
            }
            &:hover {
                color: var(--v-primary-base);
            }
        }
        .swiper-button-prev {
            left: 0;
            right: auto;
            transform: translate(-100%, -50%);
        }
        .swiper-button-next {
            left: auto;
            right: 0;
            transform: translate(100%, -50%);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-video-wrap {
        &__inner {
            padding: 0 60px;
        }
    }
    .main-video {
        ::v-deep {
            .swiper-button-prev,
            .swiper-button-next {
                width: 60px;
                height: 60px;
                &::after {
                    font-size: 40px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-video-wrap {
        &__inner {
            width: 3412px;
            padding: 0;
        }
    }
    .main-video {
        width: 1084px;
        ::v-deep {
            .swiper-button-prev {
                left: -4px;
                right: auto;
            }
            .swiper-button-next {
                left: auto;
                right: -4px;
            }
        }
    }
}
@media (min-width: 1600px) {
    .main-video {
        ::v-deep {
            .swiper-button-prev {
                left: -30px;
            }
            .swiper-button-next {
                right: -30px;
            }
        }
    }
}
</style>
