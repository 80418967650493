<template>
    <div v-if="banner" class="main-visual">
        <swiper :options="swiperOptions">
            <swiper-slide v-for="(slide, index) in banner.slides.filter((slide) => slide.image)" :key="index">
                <v-card tile flat :to="slide?.isOuterLink ? null : slide?.url" :href="slide?.isOuterLink ? slide?.url : null" :target="slide?.target">
                    <div class="main-visual__img" :style="'background-image:url(' + slide.image + ');'"></div>
                </v-card>
            </swiper-slide>
            <div class="swiper-control" slot="pagination">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                code: this.$props.code,
            },
            banner: null,

            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 12,
                loopAdditionalSlides: 1,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 1200,
                loop: true,
                pagination: {
                    el: ".main-visual .swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 20,
                    },
                    1024: {
                        spaceBetween: 30,
                    },
                    1200: {
                        spaceBetween: 80,
                    },
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { banner } = await api.v1.banners.get({
                    code: this.$props.code,
                });

                this.banner = banner;
                this.$nextTick(() => {
                    this.render();
                });
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        render() {},
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
.main-visual {
    ::v-deep {
        .swiper-container {
            overflow: visible;
        }
        .swiper-control {
            top: auto;
            bottom: 26px;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
        }
        .swiper-pagination-bullets {
            position: relative;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
        }
        .swiper-pagination-bullet {
            position: relative;
            flex: 1 1 auto;
            width: 100%;
            height: 6px;
            margin: 0 2px;
            border-radius: 0;
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.4);
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
                transform: scaleX(0);
                transform-origin: left;
                background-color: #fff;
            }
            &-active {
                &::before {
                    transform: scaleX(1);
                    transition: all ease-out 5s;
                    transition-delay: 1.2s;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 40px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 50px;
                max-width: var(--container-md);
                justify-content: end;
                padding-right: 50px;
            }
            .swiper-pagination {
                transform: none;
            }
            .swiper-pagination-bullets {
                width: 264px;
            }
            .swiper-pagination-bullet {
                margin: 0 4px;
            }
        }
    }
}
@media (min-width: 1500px) {
    .main-visual {
        ::v-deep {
            .swiper-control {
                bottom: 100px;
            }
        }
    }
}

/* main-visual */
.main-visual {
    margin: auto;
    &__img {
        width: 100%;
        padding-top: calc(1 / 1 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        max-width: 1680px;
        &__img {
            width: 100%;
            padding-top: calc(760 / 1680 * 100%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
