import axios from "@/plugins/axios";

import academy from "./academy";
import banners from "./banners";
import boards from "./boards";
import branches from "./branches";
import center from "./center";
import commonFiles from "./common-files";
import forms from "./forms";
import levels from "./levels";
import membership from "./membership";
import messages from "./messages";
import popups from "./popups";
import setting from "./setting";
import shop from "./shop";
import sns from "./sns";
import terms from "./terms";
import users from "./users";
export default {
    academy,
    banners,
    boards,
    branches,
    center,
    commonFiles,
    forms,
    levels,
    membership,
    messages,
    popups,
    setting,
    shop,
    sns,
    terms,
    users,
    email: {
        post({ to, subject, html }) {
            return axios.post("/api/console/email", { to, subject, html }).then((result) => result.data);
        },
    },
    message: {
        post({ to, subject, message }) {
            return axios.post("/api/console/message", { to, subject, message }).then((result) => result.data);
        },
    },
};
