var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "v-btn--none-active-bg pa-0",
    attrs: {
      "x-small": "",
      "fab": "",
      "color": "transparent"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_vm.accessToken ? _c('i', {
    staticClass: "icon icon-logout-dark"
  }) : _c('i', {
    staticClass: "icon icon-member-dark"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }