import axios from "@/plugins/axios";

let url = "/api/console/common-files";

export default {
    post({ directory, type, index, duration }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();
        if (directory) formData.append("directory", directory);
        if (type !== undefined) formData.append("type", type);
        if (index !== undefined) formData.append("index", index);
        if (duration) formData.append("duration", duration);
        formData.append("file", file);
        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
