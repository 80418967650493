import Vue from "vue";
import Vuetify from "vuetify";


// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    breakpoint: {
        thresholds: {
            xs: 576,
            sm: 768,
            md: 1024,
            lg: 1200,
        },
        scrollBarWidth: 0,
    },
    themes: {
        light: {
            primary: "#c2d500",
            secondary: "#a2c438",
            accent: "#82B1FF",
            error: '#FF5252',
            info: "#2196F3",
            success: '#4CAF50',
            warning: '#FFC107',
            anchor: "inherit",
            "grey-16": "#161616",
            "grey-2": "#222",
            "grey-6": "#666",
            "grey-90": "#909090",
            "grey-a": "#aaa",
            "grey-c": "#ccc",
            "grey-d": "#ddd",
            "grey-e6": "#e6e6e6",
            "grey-f8": "#f8f8f8",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            // error: '#FF5252',
            info: "#2D3539",
            content: "#EDF0F5",
            // success: '#4CAF50',
            // warning: '#FFC107',
            anchor: "#333",
        },
        // dark: {
        //     primary: colors.purple.base,
        //     secondary: '#424242',
        //     accent: '#82B1FF',
        //     error: '#FF5252',
        //     info: '#2196F3',
        //     success: '#4CAF50',
        //     warning: '#FFC107',
        // }
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function(theme) {
    return new Vuetify({
        // lang: {
        //     locales: { ko },
        //     current: "ko",
        // },
        theme: theme || basicTheme,
    });
}
