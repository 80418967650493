var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-video-wrap"
  }, [_c('div', {
    staticClass: "main-video-wrap__inner"
  }, [_c('div', {
    staticClass: "main-video"
  }, [_c('swiper', {
    ref: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.items, function (_ref) {
    var videoId = _ref.videoId;
    return _c('swiper-slide', {
      key: videoId
    }, [_c('div', {
      staticClass: "main-video__inner"
    }, [_c('div', {
      staticClass: "video-responsive"
    }, [_c('youtube', _vm._b({
      attrs: {
        "fitParent": ""
      },
      on: {
        "ready": function ($event) {
          _vm.count += 1;
        }
      }
    }, 'youtube', {
      videoId
    }, false))], 1)])]);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('button', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }), _c('button', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  })])], 2)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }