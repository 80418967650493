import api from "../../api";
import { BRANCH_GROUPS } from "../../assets/variables/constants";

export const ui = {
    namespaced: true,
    state: {
        categories: [],
    },
    getters: {
        gnb(state) {
            return [
                {
                    title: "About Us",
                    to: "/story/values/overview",
                    children: [
                        {
                            title: "Values",
                            to: "/story/values/overview",
                            children: [
                                {
                                    title: "Overview",
                                    to: "/story/values/overview",
                                },
                                // {
                                //     title: "History",
                                //     to: "/story/values/history",
                                // },
                                // {
                                //     title: "Vision",
                                //     to: "/story/values/vision/trust",
                                // },
                                {
                                    title: "R&D",
                                    to: "/story/values/rnd",
                                },
                                {
                                    title: "Live Surgery",
                                    to: "/story/values/live",
                                },
                            ],
                        },
                        {
                            title: "Events",
                            to: "/story/news",
                            children: [
                                {
                                    title: "upcoming",
                                    to: "/story/news?mode=upcoming",
                                },
                                {
                                    title: "past",
                                    to: "/story/news?mode=past",
                                },
                            ],
                            // children: Object.values(STORY_NEWS_CATEGORIES).map(({ value: category, text }) => ({
                            //     title: text,
                            //     to: {
                            //         path: "/story/news",
                            //         query: { category },
                            //     },
                            // })),
                        },
                    ],
                },
                {
                    title: "Product",
                    to: "/categories",
                    children: (state?.categories || []).map(({ name, code, color, children }) => ({
                        title: name,
                        color,
                        to: { path: `/categories/${code}` },
                        children: children?.map(({ name, _id }) => ({
                            title: name,
                            to: {
                                path: `/categories/${code}`,
                                query: { _category: _id },
                            },
                        })),
                    })),
                },
                {
                    title: "Download",
                    to: "/download-center/catalogs",
                    children: [
                        {
                            title: "Catalog",
                            to: "/download-center/catalogs",
                        },
                        {
                            title: "Abstract",
                            to: "/download-center/abstracts",
                        },
                        {
                            title: "Digital Guide S/W",
                            to: "/download-center/guides",
                        },
                        {
                            title: "CI",
                            to: "/download-center/ci",
                        },
                    ],
                },
                {
                    title: "Global",
                    to: "/global/network",
                    children: [
                        {
                            title: "Network",
                            to: "/global/network",
                            // children: Object.values(BRANCH_GROUPS).map(({ value, text }) => ({
                            //     title: text,
                            //     to: {
                            //         path: `/global/network/${value}`,
                            //     },
                            // })),
                        },
                        {
                            title: "News",
                            to: "/global/sns",
                        },
                    ],
                },
                // {
                //     title: "Minimalism in Dentium",
                //     to: "/minimalism",
                //     children: [
                //         {
                //             title: "Digital",
                //             to: "/minimalism/digital",
                //         },
                //         {
                //             title: "Sinus",
                //             to: "/minimalism/sinus",
                //         },
                //         {
                //             title: "GBR",
                //             to: "/minimalism/gbr",
                //         },
                //         {
                //             title: "Edentulous",
                //             to: "/minimalism/edentulous",
                //         },
                //         {
                //             title: "Esthetic",
                //             to: "/minimalism/esthetic",
                //         },
                //     ],
                // },
                {
                    title: "Contact Us",
                    to: "/contact",
                },
                // {
                //     title: "Story",
                //     to: "/story/values/overview",
                //     children: [
                //         {
                //             title: "Dentium Values",
                //             to: "/story/values/overview",
                //             children: [
                //                 {
                //                     title: "Overview / Brand Story",
                //                     to: "/story/values/overview",
                //                 },
                //                 {
                //                     title: "History",
                //                     to: "/story/values/history",
                //                 },
                //                 {
                //                     title: "Vision",
                //                     to: "/story/values/vision/trust",
                //                 },
                //                 {
                //                     title: "CI",
                //                     to: "/story/values/ci",
                //                 },
                //                 {
                //                     title: "R&D",
                //                     to: "/story/values/rnd",
                //                 },
                //             ],
                //         },
                //         {
                //             title: "About Dentium",
                //             to: "/story/about/notice",
                //             children: [
                //                 {
                //                     title: "Notice",
                //                     to: "/story/about/notice",
                //                 },
                //                 {
                //                     title: "Talent Award",
                //                     to: "/story/about/talent-award",
                //                 },
                //             ],
                //         },
                //         {
                //             title: "About IR",
                //             to: "/story/ir",
                //             children: [
                //                 {
                //                     title: "Notice",
                //                     to: "/story/ir/notice",
                //                 },
                //                 {
                //                     title: "IR Data",
                //                     to: "/story/ir/data",
                //                 },
                //                 {
                //                     title: "IR Analysis",
                //                     to: "/story/ir/analysis",
                //                 },
                //             ],
                //         },
                //         {
                //             title: "Dentium Global",
                //             to: "/story/global/network",
                //             children: [
                //                 {
                //                     title: "Global Network",
                //                     to: "/story/global/network",
                //                 },
                //             ],
                //         },
                //     ],
                // },
                // {
                //     title: "PRODUCT",
                //     to: "/products",
                //     children: [
                //         {
                //             title: "Products",
                //             to: "/products",
                //             children: (state?.categories || []).map(({ name, code }) => ({ title: name, to: { path: "/products", query: { category: code } } })),
                //         },
                //         {
                //             title: "Catalog",
                //             to: "/products/catalogs",
                //         },
                //     ],
                // },
                // {
                //     title: "ACADEMY",
                //     to: "/academy",
                //     children: [
                //         {
                //             title: "Main",
                //             to: "/academy",
                //         },
                //         {
                //             title: "Live",
                //             to: "/academy/live",
                //         },
                //         {
                //             title: "On-Demand",
                //             to: "/academy/on-demand",
                //         },
                //         {
                //             title: "Speaker",
                //             to: "/academy/speaker",
                //         },
                //         {
                //             title: "Seminar",
                //             to: "/academy/seminar",
                //         },
                //     ],
                // },
                // {
                //     title: "CLINICAL CASES",
                //     href: "http://www.clinicaldentium.com/",
                // },
                // {
                //     title: "DIGITAL CENTER",
                //     to: "/digital-center/download",
                //     children: [
                //         {
                //             title: "Download",
                //             to: "/digital-center/download",
                //         },
                //         {
                //             title: "Library",
                //             to: "/digital-center/library",
                //         },
                //     ],
                // },
                // {
                //     title: "CUSTOMER CENTER",
                //     to: "/customer-center",
                // },
            ];
        },
    },
    mutations: {
        setCategories(state, { categories }) {
            state.categories = categories;
        },
    },
    actions: {
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            commit("setCategories", { categories });
        },
    },
};
