<template>
    <footer class="footer">
        <!-- <v-container class="py-20">
            <v-row align="center" class="row--small">
                <v-col>
                    <ul class="footer-link">
                        <li><router-link to="/privacy-policy">Personal information handling policy</router-link></li>
                        <li><router-link to="/service-policy">Terms of Use</router-link></li>
                        <li><router-link to="/">Newsroom</router-link></li>
                        <li><router-link to="/">Notice</router-link></li>
                        <li><router-link to="/">Customer Inquiry</router-link></li>
                    </ul>
                </v-col>
                <v-col cols="12" md="auto">
                    <div class="mt-10 mt-md-0">
                        <v-select large hide-details outlined placeholder="Family Site" append-icon="mdi-chevron-down" class="familysite"
                        :items="sites" item-text="name" item-value="link" v-model="site" @change="changeD">
                        </v-select>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-divider style="border-color:#393939;"></v-divider> -->
        <v-container class="py-20 py-md-50">
            <v-row align="center">
                <v-col cols="12" md="auto">
                    <v-img class="d-none d-sm-block" max-width="156" width="100%" src="/images/logo-dark.svg"></v-img>
                    <v-img class="d-sm-none" max-width="100" width="100%" src="/images/logo-dark.svg"></v-img>
                </v-col>
                <v-col>
                    <div>
                        <ul class="footer-info">
                            <!-- <li v-for="item in info" :key="item._id">
                                <span>{{ item.term }}</span> {{ item.desc }}
                            </li> -->
                            <li>Corporate name : Dentium Co., Ltd. Representaive : Suh Seung Woo / 76, Changryong-daero 256beon-gil, Yeongtong-gu, Suwon-si, Gyeonggi-do, 1F</li>
                            <li class="clear">Business registrarion number : 123-81-57732 Mail Order Sales Report Number : 135111-0052892</li>
                            <li class="clear">COPYRIGHTⓒ 2023 DENTIUM. ALL RIGHTS RESERVED</li>
                        </ul>
                    </div>
                </v-col>
                <!-- <v-col cols="12" md="auto" order="first" order-md="last" class="d-flex flex-md-column">
                    <u-scroll-top class="mt-md-auto ml-md-auto"></u-scroll-top>
                </v-col> -->
                <v-col cols="12" md="auto" class="d-flex">
                    <u-scroll-top class="mt-auto ml-auto"></u-scroll-top>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
import UScrollTop from "@/components/dumb/u-scroll-top.vue";
import api from "@/api";

export default {
    components: {
        UScrollTop,
    },
    data() {
        return {
            setting: null,
            site: null,
            sites: [
                {
                    name: "덴티움 몰",
                    link: "https://www.naver.com/",
                },
                {
                    name: "덴티움 아카데미",
                    link: "https://www.daum.net/",
                },
                {
                    name: "Clinical Case",
                    link: "http://www.clinicaldentium.com/",
                },
                {
                    name: "Digital Center",
                    link: "http://www.clinicaldentium.com/",
                },
            ],
        };
    },
    computed: {
        info() {
            return this.setting?.footer?.info || [];
        },
        sns() {
            return (this.setting?.footer?.sns || []).filter(({ shows }) => shows);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = setting;
        },

        changeD: function() {
            window.open(this.site, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    font-family: var(--font-spoqa);
    color: #666;
    background-color: #202020;
    .container {
        max-width: calc(var(--container-lg) + 12px * 2);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
.footer-link {
    display: flex;
    flex-wrap: wrap;
    margin: -4px -10px;
    li {
        position: relative;
        padding: 4px 10px;
        &::before {
            display: none;
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, calc(-50% + 1px));
            -ms-transform: translate(0, calc(-50% + 1px));
            transform: translate(0, calc(-50% + 1px));
            width: 1px;
            height: 8px;
            background-color: #393939;
        }
        &:first-child::before {
            display: none;
        }
        a {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.2;
            color: #666;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-link {
        margin: 0 -14px;
        li {
            padding: 0 14px;
            ::before {
                display: block;
                height: 10px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.footer-info {
    font-size: 1.2rem;
    margin: -2px -6px;
    overflow: auto;
    &::after {
        content: "";
        clear: both;
        display: table;
    }
    li {
        display: inline-block;
        float: left;
        font-size: 1.2rem;
        padding: 2px 6px;
        &.clear {
            clear: left;
        }
        > span {
            margin-right: 4px;
            color: #fff;
        }
    }
    &__tit {
        font-weight: 500;
        color: #fff;
        margin-bottom: 2px;
    }
}
@media (min-width: 768px) {
    .footer-info {
        li {
            font-size: 1.4rem;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}

.copyright {
    font-size: 1.2rem;
    color: #666;
}
::v-deep {
    .familysite {
        width: 160px;
        font-size: 1.4rem;
        border-radius: 0;
        .v-input__slot {
            height: 30px !important;
        }
        .v-text-field--outlined legend {
            border: none;
        }
        &,
        input::placeholder,
        .v-select__selection--comma,
        .mdi:before {
            color: #666 !important;
        }
        input::placeholder {
            font-weight: 500;
        }
        &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
            color: #393939 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .copyright {
        font-size: 1.4rem;
    }
    ::v-deep {
        .familysite {
            .v-input__slot {
                height: 30px !important;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
