import axios from "@/plugins/axios";

import auth from "./auth";
import console from "./console";
import editor from "./editor";
// export { kakao } from "./kakao";
// export { naver } from "./naver";
import v1 from "./v1";

export default {
    auth,
    console,
    editor,
    v1,
    getResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            try {
                return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
            } catch (error) {
                var blob = new Blob([result.data]);
                blob.name = filename;
                return blob;
            }
        });
    },
    getResourcePdf(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            try {
                console.log(result);
                var blob = new Blob([result.data], { type:'application/pdf' });
                blob.name = filename;
            } catch (error) {
                var blob = new Blob([result.data], { type:'application/pdf' });
                blob.name = filename;
                return blob;
            }
        });
    },
    getMemberResource(filename, isShort = false) {
        return axios({
            url: filename,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            try {
                return new File([result.data], isShort ? filename?.split("/")?.pop() : filename);
            } catch (error) {
                var blob = new Blob([result.data]);
                blob.name = filename;
                return blob;
            }
        });
    },
    downloadMemberResource(url, name = url.split("/").pop()) {
        return axios({
            url,
            method: "GET",
            responseType: "blob",
        }).then((result) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(result.data);

            // create "a" HTLM element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    },
};
