var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "scroll-top",
    attrs: {
      "fab": "",
      "tile": "",
      "outlined": "",
      "color": "grey-6",
      "transition": "slide-x-transition"
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, [_c('span', {
    staticClass: "font-size-14 font-size-md-18 font-weight-semibold"
  }, [_vm._v("TOP")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }