import urlParser from "js-video-url-parser";

export const initCategory = (category = {}) => ({
    ...category,

    _id: category?._id,

    _parent: category?._parent,

    _parents: [...(category?._parents ?? [])],
    code: category?.code ?? null,
    name: category?.name ?? null,
    color: category?.color ?? null,
    depth: category?.depth ?? 1,
    products: [...(category?.products ?? [])],
});

export const initForm = (form = {}) => ({
    ...form,

    code: form.code || null,
    subject: form.subject || null,
    content: form.content || null,

    writer: {
        ...(form.writer || {}),
    },

    meta: {
        ...(form.meta || {}),

        name: form.meta?.name || null,
        name_first: form.meta?.name_first || null,
        name_last: form.meta?.name_last || null,

        email: form.meta?.email || null,

        _terms: form.meta?._terms || [],
    },
});

export const initSettingMainVideo = (video = {}) => ({
    youtube: video.youtube || "",
    videoId: video.youtube ? urlParser.parse(video.youtube)?.id || "" : "",
});

export const initSetting = (setting = {}) => ({
    ...setting,
    main: {
        ...(setting.main || {}),
        videos: [...(setting.main?.videos || [initSettingMainVideo()])].map(initSettingMainVideo),
    },
});
