<template>
    <client-page class="app--main">
        <!-- <full-page ref="fullpage" :options="options" id="fullpage"> -->
        <!-- S: 메인 비주얼 -->
        <main-section class="main-section--visual">
            <main-visual code="main-visual-banners-desktop" class="d-none d-md-block" />
            <main-visual code="main-visual-banners-mobile" class="d-md-none" />
        </main-section>
        <!-- E: 메인 비주얼 -->

        <!-- S: Minimalism -->
        <main-section style="background-image:url('/images/main/minimalism-bg.jpg');">
            <template #title>Minimalism</template>
            <v-container>
                <main-banners code="main-product-banners" />
                <!-- <v-row>
                    <v-col cols="12">
                        <main-product-display data-aos="fade-right" />
                    </v-col>
                    <v-col cols="12">
                        <main-banners code="main-product-banners" data-aos="fade-left" />
                    </v-col>
                </v-row> -->
            </v-container>
        </main-section>
        <!-- E: Minimalism -->

        <!-- S: Global -->
        <main-section class="pt-0">
            <template #title>Global</template>
            <div class="tab-wrap mt-n30 overflow-hidden">
                <ul class="tab tab--inline tab--underline-inline justify-center" data-aos="fade-up">
                    <li class="tab__li active">
                        <router-link to="/global/network" class="tab__btn"><span>Network</span></router-link>
                    </li>
                    <li class="tab__li">
                        <router-link to="/global/sns" class="tab__btn"><span>News</span></router-link>
                    </li>
                </ul>
            </div>
            <v-container data-aos="fade-up" data-aos-delay="200">
                <global-network-showcase />
            </v-container>
        </main-section>
        <!-- E: Global -->

        <!-- S: Clinical Case -->
        <main-section style="background-image:url('/images/main/clinical-bg.jpg');">
            <template #title>Clinical Case</template>
            <main-video />
        </main-section>
        <!-- E: Clinical Case -->

        <!-- S: Event -->
        <main-section style="background-image:url('/images/main/event-bg.jpg');">
            <template #title>Event</template>
            <v-container data-aos="fade-up" data-aos-delay="200">
                <main-events />
            </v-container>
        </main-section>
        <!-- E: Event -->

        <!-- 외부링크 갤러리 -->
        <!-- <div class="section" style="background-image:url('/images/main/main-map-bg.jpg');">
                <div class="main-section" data-aos="fade-up">
                    <v-container class="container--small">
                        <board-list hidePagination hideWriteButton code="main-gallery" skin="gallery-overlay" />
                    </v-container>
                </div>
            </div> -->
        <!-- </full-page> -->

        <!-- <v-row justify="center">
            <v-col cols="12" align="center" class="py-0">
                <span class="subtitle-1 font-weight-bold">다국어 샘플</span> <br>
                <span class="caption">(해당 내용은 테스트하고 지우면되요)</span>
            </v-col>
            <v-col cols="auto" class="py-0">
                <locale-select label="언어변경"></locale-select>
            </v-col>
            <v-col cols="12" align="center">
                <div> 한국어 번역 경로: src/locales/ko.js </div>
                <div> 영어 번역 경로: src/locales/en.js </div>
                <div> 중국어 번역 경로: src/locales/cn.js </div>
                <div class="mt-4"> 사용법 $t() 함수 안에, src/locales/{locale}.js 에서 작성한 json 키워드 나열 </div>
                <div class="font-weight-bold mt-4">JSON 작성법1</div>
                <div>
                    {
                        "key1": "value1"
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법2</div>
                <div>
                    {
                        "key1": {
                            "key2": "value2"
                        }
                    }
                </div>
                <div class="font-weight-bold mt-4">JSON 작성법3 - 우리 회사 룰 대략 아래처럼 정하죠 </div>
                <div>
                    {
                        page: {
                            section1: "내용1",
                            section2: {
                                title: "타이틀",
                                content: "내용"
                            }
                        }
                    }
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example1</div>
                    <div class="body-1">$t("common.로그인")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("common.로그인") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example2</div>
                    <div class="body-1">$t("main.section1")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section1") }}</div>
                </div>
                <div class="mt-4">
                    <div class="subtitle-1 font-weight-bold">example3</div>
                    <div class="body-1">$t("main.section2.title")</div>
                    <div class="body-1">$t("main.section2.content")</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.title") }}</div>
                    <div class="blue--text font-weight-bold">결과: {{ $t("main.section2.content") }}</div>
                </div>
            </v-col>
        </v-row> -->
        <popup-layer></popup-layer>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
// import LocaleSelect from "@/components/client/inc/locale-select.vue";
// import BoardList from "@/components/client/board/board-list.vue";
import MainSection from "@/components/client/main/main-section.vue";
import GlobalNetworkShowcase from "@/components/client/global-network/global-network-showcase.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainBanners from "@/components/client/main/main-banners.vue";
// import MainProductDisplay from "@/components/client/main/main-product-display.vue";
import MainVideo from "@/components/client/main/main-video.vue";
import MainEvents from "@/components/client/main/main-events.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        // LocaleSelect,
        // BoardList,
        MainSection,
        MainVisual,
        MainBanners,
        // MainProductDisplay,
        GlobalNetworkShowcase,
        MainVideo,
        MainEvents,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data() {
        return {
            // options: {
            //     licenseKey: "BE202675-E3234C07-967F61A1-57A110B1",
            //     fitToSection: true,
            //     scrollOverflow: true,
            //     responsiveWidth: 1200,
            //     responsiveHeight: 800,
            //     paddingTop: "100px",
            //     afterLoad: this.afterLoad,
            // },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // AOS.init({
            //     once: false,
            //     offset: 240,
            //     duration: 500,
            //     easing: "ease-in-out",
            // });
        },
        // afterLoad: function() {
        //     document.querySelectorAll(".fp-table.active .aos-init").forEach((el) => {
        //         el.classList.add("aos-animate");
        //     });
        // },
    },
};
</script>
