import catalogues from "./catalogues";
import categories from "./categories";
import documents from "./documents";
import products from "./products";
import zones from "./zones";

export default {
    catalogues,
    categories,
    documents,
    products,
    zones,
};
