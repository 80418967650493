import academy from "./academy";
import banners from "./banners";
import boards from "./boards";
import branches from "./branches";
import center from "./center";
import forms from "./forms";
import me from "./me";
import membership from "./membership";
import message from "./message";
import setting from "./setting";
import shop from "./shop";
import sns from "./sns";
import terms from "./terms";
import users from "./users";
import popups from "./popups";

export default {
    academy,
    banners,
    boards,
    branches,
    center,
    forms,
    me,
    membership,
    message,
    setting,
    shop,
    sns,
    terms,
    users,
    popups,
};
