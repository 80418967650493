<template>
    <v-row align="center" justify="end" justify-xl="center" class="row--small">
        <v-col cols="auto">
            <v-menu offset-y content-class="menu-arrow">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" fab color="transparent" class="v-btn--none-active-bg pa-0"><i class="icon icon-share-dark"></i></v-btn>
                </template>
                <v-list>
                    <!-- <v-list-item href="/facebook" target="_blank">
                        <v-row class="row--small">
                            <v-col cols="auto"><i class="icon icon-facebook"></i></v-col>
                            <v-col>
                                <div class="mt-n4">
                                    <v-list-item-title>Facebook</v-list-item-title>
                                    <div class="font-size-14 grey-90--text">View More</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item> -->
                    <v-list-item href="https://www.youtube.com/user/dentiumworld" target="_blank">
                        <v-row class="row--small">
                            <v-col cols="auto"><i class="icon icon-youtube"></i></v-col>
                            <v-col>
                                <div class="my-n2">
                                    <v-list-item-title>Youtube</v-list-item-title>
                                    <div class="font-size-14 grey-90--text">View More</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item href="https://brightz.co.kr/" target="_blank">
                        <v-row class="row--small">
                            <v-col cols="auto"><i class="icon icon-bright"></i></v-col>
                            <v-col>
                                <div class="my-n2">
                                    <v-list-item-title>bright Implant</v-list-item-title>
                                    <div class="font-size-14 grey-90--text">View More</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                    <v-list-item href="http://www.clinicaldentium.com" target="_blank">
                        <v-row class="row--small">
                            <v-col cols="auto"> <v-icon size="20">mdi-tooth-outline</v-icon> </v-col>
                            <v-col>
                                <div class="my-n2">
                                    <v-list-item-title>Clinical Dentium</v-list-item-title>
                                    <div class="font-size-14 grey-90--text">View More</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        <!-- <v-col cols="auto">
            <v-btn x-small fab color="transparent" href="https://3d.cobenef.com/dentium_showroom2/#autoplay&flipmouse" target="_blank" class="v-btn--none-active-bg pa-0">
                <i class="icon icon-vr-dark"></i>
            </v-btn> -->
            <!-- <v-menu offset-y content-class="menu-arrow">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" fab color="transparent" class="v-btn--none-active-bg pa-0">
                        <i class="icon icon-link-dark"></i>
                        <i class="icon icon-vr-dark"></i>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item href="http://www.clinicaldentium.com/" target="_blank">
                        <v-row class="row--small">
                            <v-col cols="auto"><i class="icon icon-clinical"></i></v-col>
                            <v-col>
                                <div class="mt-n4">
                                    <v-list-item-title>Clinical case</v-list-item-title>
                                    <div class="font-size-14 grey-90--text">View More</div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-menu> -->
        <!-- </v-col> -->
        <!-- <v-col cols="auto">
            <v-menu offset-y content-class="menu-arrow">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small v-bind="attrs" v-on="on" fab color="transparent" class="v-btn--none-active-bg pa-0"><i class="icon icon-global-dark"></i></v-btn>
                </template>
                <v-list>
                    <v-list-item to="/ko">
                        <v-list-item-title>KOR</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/">
                        <v-list-item-title>ENG</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col> -->
        <v-col v-if="accessToken" cols="auto">
            <v-btn x-small fab color="transparent" to="/mypage/myinfo" class="v-btn--none-active-bg pa-0"><i class="icon icon-mypage-dark"></i></v-btn>
        </v-col>
        <v-col v-else cols="auto">
            <gnb-login-button></gnb-login-button>
        </v-col>
        <!-- <v-col cols="auto">
            <v-dialog v-model="dialog" transition="scroll-y-transition" hide-overlay content-class="v-dialog--search" persistent>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small fab color="transparent" class="v-btn--none-active-bg pa-0"><i class="icon icon-search-dark"></i></v-btn>
                </template>

                <div class="v-dialog--search__contants">
                    <v-container>
                        <user-menu-search @close="close" />
                    </v-container>
                    <div class="v-dialog--search__close-wrap">
                        <v-btn icon tile outlined color="grey-d" class="v-dialog--search__close" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    </div>
                </div>
            </v-dialog>
        </v-col> -->
    </v-row>
</template>

<script>
import gnbLoginButton from "@/components/client/login/gnb-login-button.vue";
import UserMenuSearch from "./user-menu-search.vue";

export default {
    components: {
        gnbLoginButton,
        UserMenuSearch,
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    watch: {
        dialog(dialog) {
            console.log({ dialog });
        },
    },
    methods: {
        close() {
            console.log("closed called");
            this.dialog = !this.dialog;
        },
    },
};
</script>

<style scoped>
>>> .v-dialog--search {
    align-self: start;
    margin: calc(var(--header-body) - 1px) 0 0;
    box-shadow: none;
    border-radius: 0;
}
.v-dialog--search__contants {
    position: relative;
    padding: 40px 0 60px;
    background-color: #fff;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}
.v-dialog--search__close-wrap {
    position: absolute;
    bottom: -1px;
    right: 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .v-dialog--search__contants {
        padding: 60px 0;
    }
}
@media (min-width: 1024px) {
    .v-dialog--search__contants {
        padding: 100px 0;
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
    .v-dialog--search__close-wrap {
        bottom: 50%;
        right: 50%;
        -webkit-transform: translate(50%, 50%);
        -ms-transform: translate(50%, 50%);
        transform: translate(50%, 50%);
        max-width: calc(var(--container-lg) + 24px);
        width: 100%;
        text-align: right;
        padding: 0 12px;
    }
    .v-dialog--search__close {
        position: absolute;
        top: 50%;
        right: 12px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
}
</style>
