<template>
    <v-btn @click.native="navigate" x-small fab color="transparent" class="v-btn--none-active-bg pa-0">
        <i v-if="accessToken" class="icon icon-logout-dark"></i>
        <i v-else class="icon icon-member-dark"></i>
    </v-btn>
</template>
<script>
export default {
    methods: {
        navigate(){
            if(this.accessToken){
                this.$store.dispatch("logout");
                window.location.reload(true);
            }
            else{
                this.$router.push(`/login`);
            }
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    }
}
</script>