<template>
    <v-btn fab tile outlined color="grey-6" transition="slide-x-transition" class="scroll-top" @click="$vuetify.goTo(target, options)">
        <span class="font-size-14 font-size-md-18 font-weight-semibold">TOP</span>
    </v-btn>
</template>

<script>
export default {
    data(){
        return {
            type: 'number',
            number: 0,
            duration: 500,
            offset: 0,
            easing: 'easeInOutCubic',

            show: false,
        };
    },
    mounted(){
    },
    methods: {
    },
    computed: {
        target : function() {
            const value = this[this.type]
            if (!isNaN(value)) return Number(value)
            else return value
        },
        options : function() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            }
        },
    },
}
</script>