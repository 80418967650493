var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, _vm._l(_vm.rows, function (row, rowIndex) {
    return _c('div', {
      key: rowIndex,
      staticClass: "board-list-gallery-overlay"
    }, [_c('v-row', {
      staticClass: "row--large"
    }, [_vm._l(row, function (item, itemIndex) {
      var _item$thumb;
      return [(item === null || item === void 0 ? void 0 : item.mode) == 'item' ? [_c('v-col', {
        key: `${rowIndex}-${itemIndex}`,
        attrs: {
          "cols": "6",
          "md": "4",
          "lg": ""
        }
      }, [_c('div', {
        staticClass: "board-list-gallery-overlay__inner"
      }, [_c('router-link', {
        staticClass: "board-list-gallery-overlay__link",
        attrs: {
          "to": `/story/news/${item === null || item === void 0 ? void 0 : item._id}`
        }
      }, [_c('div', {
        staticClass: "board-list-gallery-overlay__img",
        style: {
          backgroundImage: `url('${item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url}')`
        }
      }), _c('div', {
        staticClass: "board-list-gallery-overlay__fixed"
      }, [_c('i', {
        staticClass: "icon icon-insta-lg"
      })]), _c('div', {
        staticClass: "board-list-gallery-overlay__con"
      }, [_c('div', {
        staticClass: "board-list-gallery-overlay__tit"
      }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.subject))]), _c('div', {
        staticClass: "board-list-gallery-overlay__arrow"
      }, [_c('i', {
        staticClass: "icon icon-right-dark"
      })])])])], 1)])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.mode) == 'spacer' ? [_c('v-spacer', {
        key: `${rowIndex}-${itemIndex}`
      })] : _vm._e(), (item === null || item === void 0 ? void 0 : item.mode) == 'more' ? [_c('v-col', {
        key: `${rowIndex}-${itemIndex}`,
        attrs: {
          "cols": "12",
          "lg": "auto"
        }
      }, [_c('v-card', {
        staticClass: "d-flex align-center w-lg-210px h-lg-100 pl-18 pl-lg-0 pl-xl-80",
        attrs: {
          "outlined": "",
          "tile": "",
          "color": "transparent",
          "to": item === null || item === void 0 ? void 0 : item.to
        }
      }, [_c('div', {
        staticClass: "font-size-16 font-size-lg-22 grey--text text--darken-4"
      }, [rowIndex == 1 ? _c('div', {
        staticClass: "font-weight-bold mb-4"
      }, [_vm._v("Past")]) : _c('div', {
        staticClass: "font-weight-bold mb-4"
      }, [_vm._v("Upcoming")]), _c('div', {
        staticClass: "d-flex align-center font-size-14 font-size-lg-18"
      }, [_vm._v("More "), _c('i', {
        staticClass: "icon icon-right-dark ml-10"
      })])])])], 1)] : _vm._e()];
    })], 2)], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }