<template>
    <header class="header header--fixed" v-scroll="fixedScroll">
        <!-- S: head -->
        <!-- <div class="header__head">
            <v-container>
                <v-row no-gutters justify="space-between">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <user-menu></user-menu>
                    </v-col>
                </v-row>
            </v-container>
        </div> -->
        <!-- E: head -->
        <!-- S: body -->
        <div class="header__body">
            <v-container>
                <h1 class="header__logo">
                    <router-link to="/" title="홈으로 이동"><span class="d-sr-only">Dentium</span></router-link>
                </h1>
                <div class="header__contents">
                    <div class="header__gnb">
                        <!-- S: Gnb -->
                        <client-gnb className="gnb"></client-gnb>
                        <!-- E: Gnb -->
                    </div>
                    <!-- S: Header Search -->
                    <!-- <main-header-search /> -->
                    <!-- E: Header Search -->

                    <div class="header__right">
                        <user-menu />

                        <!-- S: PC hamburger -->
                        <div class="hamburger-btn-pc ml-lg-30">
                            <span></span>
                        </div>
                        <!-- E: PC hamburger -->
                    </div>

                    <div v-if="accessToken" style="order:3;" class="d-xl-none text-right mt-20">
                        <v-btn x-small outlined color="grey-e6" @click.native="navigate"><span>Logout</span></v-btn>
                    </div>

                    <!-- S: 모바일 user-menu -->
                    <!-- <div class="user-menu--moblie">
                        <user-menu></user-menu>
                    </div> -->
                    <!-- E: 모바일 user-menu -->
                </div>
            </v-container>
        </div>
        <!-- E: body -->
        <!-- S: hamburger -->
        <div class="hamburger-btn">
            <span></span>
        </div>
        <!-- E: hamburger -->

        <!-- <div class="header__bg"></div> -->

        <!-- S: Side gnb -->
        <side-gnb />
        <!-- E: Side gnb -->
    </header>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import UserMenu from "@/components/client/inc/user-menu.vue";
import LocaleSelect from "@/components/client/inc/locale-select.vue";
import MainHeaderSearch from "./main-header-search.vue";
import SideGnb from "./side-gnb.vue";

export default {
    props: {
        disableScrollTop: { type: Boolean, default: false },
    },
    components: {
        ClientGnb,
        UserMenu,
        LocaleSelect,
        MainHeaderSearch,
        SideGnb,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            dialog: false,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작

        if (this.isDesktop) {
            this.initPcEvent();
        }
        if (this.isMobile) {
            this.initMobileEvent();
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        if (this.isDesktop) {
            this.destroyPcEvent();
        }
        if (this.isMobile) {
            this.destroyMobileEvent();
        }
    },
    watch: {
        $route() {
            if (!this.disableScrollTop) window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
        },
        isDesktop: function() {
            if (this.isDesktop) {
                this.initPcEvent();
            } else {
                this.destroyPcEvent();
            }
        },
        isMobile: function() {
            if (this.isMobile) {
                this.initMobileEvent();
            } else {
                this.destroyMobileEvent();
            }
        },
    },
    methods: {
        navigate(){
            if(this.accessToken){
                this.$store.dispatch("logout");
                window.location.reload(true);
            }
            else{
                this.$router.push(`/login`);
            }
        },
        fixedScroll: function() {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > 50) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // PC
        initPcEvent: function() {
            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".hamburger-btn-pc");
            hamburgerBtnPc.addEventListener("click", pcMenuToggle);

            // gnb
            // var lis = document.getElementsByClassName("gnb__li");
            // for (var i = 0; i < lis.length; i++) {
            //     lis[i].addEventListener("mouseenter", gnbLiOver);
            //     lis[i].addEventListener("mouseleave", gnbLiOut);
            // }

            var gnb = document.querySelector(".gnb");
            gnb.addEventListener("mouseenter", gnbOver);
            gnb.addEventListener("mouseleave", gnbOut);

            var slideNavLink = document.querySelectorAll(".slide-nav__link");
            slideNavLink.forEach(function(el) {
                el.addEventListener("click", hideSideGnb);
            });
        },
        destroyPcEvent: function() {
            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".hamburger-btn-pc");
            hamburgerBtnPc.removeEventListener("click", pcMenuToggle);

            // gnb
            // var lis = document.getElementsByClassName("gnb__li");
            // for (var i = 0; i < lis.length; i++) {
            //     lis[i].removeEventListener("mouseenter", gnbLiOver);
            //     lis[i].removeEventListener("mouseleave", gnbLiOut);
            // }

            var gnb = document.querySelector(".gnb");
            gnb.removeEventListener("mouseenter", gnbOver);
            gnb.removeEventListener("mouseleave", gnbOut);

            var slideNavLink = document.querySelectorAll(".slide-nav__link");
            slideNavLink.forEach(function(el) {
                el.removeEventListener("click", hideSideGnb);
            });
        },
        // Mobile
        initMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector(".hamburger-btn");
            hamburgerBtn.addEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb-sub__link");

            gnbLinkToggle.forEach(function(el) {
                el.addEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function(el) {
                el.addEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function(el) {
                el.addEventListener("click", hideGnb);
            });
        },
        destroyMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector(".hamburger-btn");
            hamburgerBtn.removeEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb__sub >li >a");

            gnbLinkToggle.forEach(function(el) {
                el.removeEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function(el) {
                el.removeEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function(el) {
                el.removeEventListener("click", hideGnb);
            });
        },
    },
};

// PC
// function gnbLiOver() {
//     var headerBg = document.querySelector(".header__bg");
//     var gnbSub = this.querySelector(".gnb__sub");

//     this.classList.add("active");
//     if (gnbSub == null) {
//         headerBg.classList.add("none");
//     }
// }
// function gnbLiOut() {
//     var headerBg = document.querySelector(".header__bg");
//     var gnbSub = this.querySelector(".gnb__sub");

//     this.classList.remove("active");
//     if (gnbSub == null) {
//         headerBg.classList.remove("none");
//     }
// }
function gnbOver() {
    var header = document.querySelector(".header");
    header.classList.add("menu-active");
}
function gnbOut() {
    var header = document.querySelector(".header");
    header.classList.remove("menu-active");
}
function pcMenuToggle() {
    var HTML = document.querySelector("html");

    HTML.classList.toggle("pc-menu-on");
}
// function pcMenuHide() {
//     var HTML = document.querySelector("html");

//     HTML.classList.remove("pc-menu-on");
// }

// Mobile
function mobileMenuToggle() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");

    header.classList.toggle("mobile-menu-on");
    // HTML.classList.toggle('hidden');

    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function(el) {
            el.parentNode.classList.remove("active");
        });
    }
}
function toggleAccordion(el) {
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var targetLi = el.currentTarget.parentNode.classList;
    var target = el.currentTarget;

    el.preventDefault();
    if (targetLi.contains("active")) {
        targetLi.remove("active");
    } else {
        gnbLinkToggle.forEach(function(el) {
            el.parentNode.classList.remove("active");
        });
        targetLi.add("active");
    }
}
function hideGnb() {
    var header = document.querySelector(".header");
    var lis = document.querySelectorAll("gnb__li");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");

    header.classList.remove("mobile-menu-on");

    if (lis.classList.contains("active")) {
        lis.forEach(function(el) {
            el.classList.remove("active");
        });
    }
    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function(el) {
            el.parentNode.classList.remove("active");
        });
    }
}
function hideSideGnb() {
    var HTML = document.querySelector("html");

    if (HTML.classList.contains("pc-menu-on")) {
        HTML.classList.remove("pc-menu-on");
    }
}
</script>
