<template>
    <v-text-field v-model="searchValue" v-bind="$attrs" outlined hide-details @input="$emit('input', searchValue)" @keydown.enter="$emit('search')"><slot /></v-text-field>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        searchValue: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            this.searchValue = this.value;
        },
    },
};
</script>

<style scoped></style>
