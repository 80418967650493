<template>
    <div class="network-map">
        <div class="network-map__img"><img src="/images/sub/story/global/network-map.png" alt="이미지" /></div>

        <template v-for="{ text, value, color, countries } in items">
            <v-menu :key="value" open-on-hover offset-y content-class="menu-arrow network-map__menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="{ ...attrs, color }" v-on="on" rounded :class="`network-map__btn network-map__${value}`">
                        <span class="white--text">{{ text }}</span>
                    </v-btn>
                </template>
                <div class="network-map__menu-inner">
                    <ul class="country-items">
                        <template v-for="({ name, flag }, index) in countries">
                            <li :key="`country-item-${index}-${name}`" class="country-item">
                                <router-link class="country-item__link" :to="{ path: `/global/network/${value}`, query: { flag } }">
                                    <span class="country-item__flag">{{ flag }}</span>
                                    <span>{{ name }}</span>
                                </router-link>
                            </li>
                        </template>
                    </ul>
                </div>
            </v-menu>
        </template>
    </div>
</template>

<script>
import api from "@/api";
import { BRANCH_GROUPS } from "@/assets/variables";

const branchGroups = Object.values(BRANCH_GROUPS);
export default {
    data: () => ({
        loading: false,
        branches: [],
        branchGroups,
    }),
    computed: {
        items() {
            return branchGroups.map((group) => ({
                ...group,
                countries: this.branches.filter(({ company }) => company?.group == group?.value).reduce((countries, { zone }) => (countries.some(({ name }) => name == zone?.countryName) ? countries : countries.concat({ name: zone?.countryName, flag: zone?.countryFlag })), []),
            }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                const { branches = [] } = await api.v1.branches.gets();
                this.branches = branches.sort((a, b) => {
                    if (a?.zone?.countryIndex != b?.zone?.countryIndex) {
                        return a?.zone?.countryIndex < b?.zone?.countryIndex ? -1 : 1;
                    } else return a?.zone?.countryName?.localeCompare?.(b?.zone?.countryName);
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.network-map {
    position: relative;
    max-width: 1200px;
    &__btn {
        position: absolute;
        z-index: 1;
        min-width: auto !important;
        height: 28px !important;
        font-size: 1.4rem;
        padding: 0 10px !important;
    }
    &__africa {
        top: 59%;
        left: 51%;
    }
    &__asia-pacific {
        top: 27.00296735905045%;
        left: 72.5%;
    }
    &__america {
        top: 21.66172106824926%;
        left: 13.83333333333333%;
    }
    &__europe {
        top: 28.18991097922849%;
        left: 55.5%;
    }
    &__middle-east {
        top: 45.6973293768546%;
        left: 55%;
    }
    &__russia-cis {
        top: 13.35311572700297%;
        left: 65.33333333333333%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .network-map {
        &__btn {
            height: 32px !important;
            font-size: 1.6rem;
            padding: 0 16px !important;
        }
    }
}
@media (min-width: 1200px) {
}

.country-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 1.4rem;
    color: #161616;
    margin: -2px -4px;
}
.country-item {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    padding: 2px 4px;
    &::before {
        content: "·";
        padding-right: 4px;
    }
    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &__flag {
        font-family: Tossface;
        padding-right: 4px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .country-item {
        &::before {
            padding-right: 8px;
        }
        &__flag {
            font-size: 1.8rem;
            padding-right: 8px;
        }
    }
}
@media (min-width: 1024px) {
    .country-items {
        font-size: 1.6rem;
        margin: -4px -6px;
    }
    .country-item {
        padding: 4px 6px;
    }
}
@media (min-width: 1200px) {
}
</style>
