<template>
    <div>
        <div class="board-container">
            <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="board-list-gallery-overlay">
                <v-row class="row--large">
                    <template v-for="(item, itemIndex) in row">
                        <template v-if="item?.mode == 'item'">
                            <v-col cols="6" md="4" lg="" :key="`${rowIndex}-${itemIndex}`">
                                <div class="board-list-gallery-overlay__inner">
                                    <router-link :to="`/story/news/${item?._id}`" class="board-list-gallery-overlay__link">
                                        <div class="board-list-gallery-overlay__img" :style="{ backgroundImage: `url('${item?.thumb?.url}')` }"></div>
                                        <div class="board-list-gallery-overlay__fixed"><i class="icon icon-insta-lg"></i></div>
                                        <div class="board-list-gallery-overlay__con">
                                            <div class="board-list-gallery-overlay__tit">{{ item?.subject }}</div>
                                            <!-- <div class="board-list-gallery-overlay__date">{{ board.createdAt.toDate() }}</div>
                                                <div class="board-list-gallery-overlay__txt">{{ board.summary }}</div> -->
                                            <div class="board-list-gallery-overlay__arrow"><i class="icon icon-right-dark"></i></div>
                                        </div>
                                    </router-link>
                                </div>
                            </v-col>
                        </template>
                        <template v-if="item?.mode == 'spacer'">
                            <v-spacer :key="`${rowIndex}-${itemIndex}`" />
                            <!-- <v-col cols="6" md="" :key="`${rowIndex}-${itemIndex}`"></v-col> -->
                        </template>
                        <template v-if="item?.mode == 'more'">
                            <v-col cols="12" lg="auto" :key="`${rowIndex}-${itemIndex}`">
                                <v-card outlined tile color="transparent" :to="item?.to" class="d-flex align-center w-lg-210px h-lg-100 pl-18 pl-lg-0 pl-xl-80">
                                    <div class="font-size-16 font-size-lg-22 grey--text text--darken-4">
                                        <div v-if="rowIndex == 1" class="font-weight-bold mb-4">Past</div>
                                        <div v-else class="font-weight-bold mb-4">Upcoming</div>
                                        <div class="d-flex align-center font-size-14 font-size-lg-18">More <i class="icon icon-right-dark ml-10"></i></div>
                                    </div>
                                </v-card>
                            </v-col>
                        </template>
                    </template>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components: {},
    data: () => ({
        seminars__next: [],
        seminars__past: [],

        limit: 3,
        loading: false,
    }),
    computed: {
        rows() {
            const base = Array(3).fill(null);
            return [
                [
                    ...base.map((item, index) => ({ ...(this.seminars__next?.[index] || {}), mode: this.seminars__next?.[index] ? "item" : "spacer" })),
                    {
                        mode: "more",
                        to: { path: "/story/news/", query: { mode: "upcoming" } },
                    },
                ],
                [
                    ...base.map((item, index) => ({ ...(this.seminars__past?.[index] || {}), mode: this.seminars__past?.[index] ? "item" : "spacer" })),

                    {
                        mode: "more",
                        to: { path: "/story/news/", query: { mode: "past" } },
                    },
                ],
            ];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { limit } = this;
                this.seminars__next = (await api.v1.academy.seminars.gets({ headers: { skip: 0, limit, sort: JSON.stringify({ datetime: 1 }) }, params: { mode: "upcoming" } }))?.seminars;
                this.seminars__past = (await api.v1.academy.seminars.gets({ headers: { skip: 0, limit, sort: JSON.stringify({ datetime: -1 }) }, params: { mode: "past" } }))?.seminars;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .row--large {
        margin: -14px;
    }
    .row--large > [class*="col"] {
        padding: 14px;
    }
}
@media (min-width: 1024px) {
    .row--large {
        margin: -14px;
    }
    .row--large > [class*="col"] {
        padding: 14px;
    }
}
@media (min-width: 1400px) {
    .row--large {
        margin: -20px;
    }
    .row--large > [class*="col"] {
        padding: 20px;
    }
}

/* Board Style - Gallery Overlay */
.board-list-gallery-overlay {
    width: 100%;
    & + & {
        margin-top: 20px;
    }
    .board-empty-message {
        padding: 60px 20px;
    }
    .v-card--link:focus:before {
        opacity: 0;
    }
    &__inner {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
    }
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: all ease-out 0.4s;
    }
    &__fixed {
        position: absolute;
        top: 20px;
        right: 20px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.4s;
    }
    &__con {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 18px;
        border-top: 4px solid var(--v-primary-base);
        background-color: rgba(0, 0, 0, 0.7);
        transition: all ease-out 0.3s;
    }
    &__tit {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.5;
        color: #fff;
        max-height: 3em;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__arrow {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .board-list-gallery-overlay {
        .board-empty-message {
            padding: 20px 10px;
        }

        &__fixed {
            opacity: 1;
            visibility: visible;
        }
        &__con {
            top: 100%;
            padding: 24px;
        }
        &__link:hover {
            .board-list-gallery-overlay {
                &__img {
                    opacity: 1;
                    visibility: visible;
                }
                &__fixed {
                    opacity: 0;
                    visibility: hidden;
                }
                &__con {
                    top: 0;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .board-list-gallery-overlay {
        .board-empty-message {
            padding: 40px 20px;
        }
        &__tit {
            font-size: 2.2rem;
        }
    }
}
</style>
