var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-section"
  }, [this.$slots['title'] ? _c('div', {
    staticClass: "tit-wrap align-center mb-lg-60 overflow-hidden"
  }, [_c('h2', {
    staticClass: "tit text-center",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("title")], 2), _vm._t("title-actions")], 2) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }