var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', [_c('u-text-field', {
    attrs: {
      "placeholder": "Please enter a search term."
    },
    on: {
      "search": _vm.search
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input",
    attrs: {
      "block": "",
      "color": "grey-6"
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v("Search")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }