var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-gnb-wrap"
  }, [_vm.accessToken ? _c('div', {
    staticClass: "side-gnb-wrap__right"
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "grey-e6"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Logout")])])], 1) : _vm._e(), _c('v-container', [_c('ul', {
    staticClass: "slide-nav-1dep"
  }, [_vm._l(_vm.gnb, function (item) {
    return [!item.children ? _c('li', {
      key: item.title,
      staticClass: "slide-nav-1dep_li"
    }, [item.href ? _c('a', {
      staticClass: "slide-nav__link",
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _c('router-link', {
      staticClass: "slide-nav__link",
      attrs: {
        "to": item.to
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])])], 1) : _c('li', {
      key: item.title,
      staticClass: "slide-nav-1dep_li"
    }, [item.href ? _c('a', {
      staticClass: "slide-nav__link",
      attrs: {
        "href": item.href,
        "target": "_blank"
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]) : _c('router-link', {
      staticClass: "slide-nav__link",
      attrs: {
        "to": item.to
      }
    }, [_c('h2', [_vm._v(_vm._s(item.title))])]), _c('div', {
      staticClass: "slide-nav-2dep"
    }, [_c('v-expansion-panels', [_vm._l(item.children, function (child) {
      return [!child.children ? _c('div', {
        key: child.title,
        staticClass: "slide-nav-2dep__li"
      }, [child.href ? _c('a', {
        staticClass: "slide-nav__link slide-nav-2dep__link",
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_vm._v(_vm._s(child.title))]) : _c('router-link', {
        staticClass: "slide-nav__link slide-nav-2dep__link",
        attrs: {
          "to": child.to
        }
      }, [_vm._v(_vm._s(child.title))])], 1) : _c('v-expansion-panel', {
        key: child.title,
        staticClass: "slide-nav-2dep__li"
      }, [_c('v-expansion-panel-header', {
        attrs: {
          "expand-icon": "mdi-plus"
        }
      }, [child.href ? _c('a', {
        staticClass: "slide-nav__link slide-nav-2dep__link",
        attrs: {
          "href": child.href,
          "target": "_blank"
        }
      }, [_c('h2', [_vm._v(_vm._s(child.title))])]) : _c('router-link', {
        staticClass: "slide-nav__link slide-nav-2dep__link",
        attrs: {
          "to": child.to
        }
      }, [_c('h2', [_vm._v(_vm._s(child.title))])])], 1), _c('v-expansion-panel-content', [_c('ul', {
        staticClass: "slide-nav-3dep"
      }, [_vm._l(child.children, function (grandChild) {
        return [!grandChild.children ? _c('li', {
          key: grandChild.title,
          staticClass: "slide-nav-3dep__li",
          on: {
            "click": _vm.pcMenuHide
          }
        }, [grandChild.href ? _c('a', {
          staticClass: "slide-nav__link slide-nav-3dep__link",
          attrs: {
            "href": grandChild.href,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(grandChild.title))]) : _c('router-link', {
          staticClass: "slide-nav__link slide-nav-3dep__link",
          attrs: {
            "to": grandChild.to
          }
        }, [_vm._v(_vm._s(grandChild.title))])], 1) : _vm._e()];
      })], 2)])], 1)];
    })], 2)], 1)], 1)];
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }