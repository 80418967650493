<template>
    <div v-if="className == 'sub-tab-wrap'" :class="'tab-wrap ' + className">
        <template v-for="item in gnb">
            <ul v-if="item.title == title" :key="item.title" class="tab tab--inline tab--underline-inline justify-center">
                <li v-for="child in item.children" class="tab__li" :class="{ active: tabActive == child.title }" :key="child.title">
                    <a v-if="child.href" class="tab__btn" :href="child.href" target="_blank">
                        <span>{{ child.title }}</span>
                    </a>
                    <router-link v-else class="tab__btn" :to="child.to">
                        <span>{{ child.title }}</span>
                    </router-link>
                </li>
            </ul>
        </template>
    </div>

    <ul v-else-if="className == 'gnb'" :class="className">
        <template v-for="item in gnb">
            <li v-if="!item.children" :class="className + '__li'" :key="item.title">
                <a v-if="item.href" :href="item.href" target="_blank" :class="className + '__link'">
                    <h2>{{ item.title }}</h2>
                </a>
                <router-link v-else :to="item.to" :class="className + '__link'">
                    <h2>{{ item.title }}</h2>
                </router-link>
            </li>

            <li v-else :class="className + '__li'" :key="item.title">
                <div :class="className + '__link gnb__link--toggle d-xl-none'">
                    <h2>{{ item.title }}</h2>
                </div>
                <router-link :to="item.to" :class="className + '__link d-none d-xl-flex'">
                    <h2>{{ item.title }}</h2>
                </router-link>
                <a v-if="item.href" :href="item.href" target="_blank" :class="className + '__link'">
                    <h2>{{ item.title }}</h2>
                </a>

                <div :class="className + '__sub'">
                    <v-row no-gutters>
                        <template v-for="child in item.children">
                            <v-col :cols="isDesktop ? 3 : 12" v-if="!child.children" :key="child.title">
                                <div :class="className + '__sub-inner'" :style="child?.color ? `background-color: ${child?.color}` : undefined">
                                    <a v-if="child.href" :href="child.href" target="_blank" :class="className + '-sub__link'">{{ child.title }}</a>
                                    <router-link v-else :to="child.to" :class="className + '-sub__link'">{{ child.title }}</router-link>
                                </div>
                            </v-col>
                            <v-col :cols="isDesktop ? 3 : 12" v-else :key="child.title">
                                <div :class="className + '__sub-inner'" :style="child?.color ? `background-color: ${child?.color}` : undefined">
                                    <v-row no-gutters align="center">
                                        <v-col cols="auto">
                                            <a v-if="child.href" :href="child.href" target="_blank" :class="className + '-sub__link'">
                                                <h2>{{ child.title }}</h2>
                                            </a>
                                            <router-link v-else :to="child.to" :class="className + '-sub__link'">
                                                <h2>{{ child.title }}</h2>
                                            </router-link>
                                        </v-col>
                                        <!-- <v-col cols="auto" v-if="child?.children?.length">
                                            <v-btn text icon tile height="28" width="28" class="ml-6 ml-lg-14" @click="toggle(child)">
                                                <v-fab-transition hide-on-leave>
                                                    <v-icon v-show="getIsChildToShow(child)" color="#5e8601">mdi-minus</v-icon>
                                                </v-fab-transition>
                                                <v-fab-transition hide-on-leave>
                                                    <v-icon v-show="!getIsChildToShow(child)" color="#5e8601">mdi-plus</v-icon>
                                                </v-fab-transition>
                                            </v-btn>
                                        </v-col> -->
                                    </v-row>

                                    <v-expand-transition>
                                        <!-- <div v-show="getIsChildToShow(child)"> -->
                                        <ul :class="className + '__sub2'">
                                            <template v-for="grandChild in child.children">
                                                <li v-if="!grandChild.children" :key="grandChild.title">
                                                    <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="className + '-sub2__link text-truncate'">{{ grandChild.title }}</a>
                                                    <router-link v-else :to="grandChild.to" :class="className + '-sub2__link text-truncate'">{{ grandChild.title }}</router-link>
                                                </li>
                                            </template>
                                        </ul>
                                        <!-- </div> -->
                                    </v-expand-transition>
                                </div>
                            </v-col>
                        </template>
                    </v-row>
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    props: {
        className: String,
        indexNum: String,
        title: String,
        tabActive: String,
    },
    data: () => ({
        window: {
            width: 0,
            height: 0,
        },
        children__shows: [],
    }),
    computed: {
        ...mapGetters("ui", ["gnb"]),
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created: function () {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.init();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        ...mapActions("ui", ["getCategories"]),
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        async init() {
            this.getCategories();
        },
        toggle(child) {
            const index = this.children__shows.findIndex(({ title }) => title == child.title);
            if (0 <= index) this.children__shows.splice(index, 1);
            else this.children__shows.push(child);
        },
        getIsChildToShow(child) {
            return this.children__shows.some(({ title }) => title == child.title);
        },
    },
};
</script>
