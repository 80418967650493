var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "banner",
    attrs: {
      "data-aos": "fade"
    }
  }, [_c('div', {
    staticClass: "banner__inner"
  }, [_c('v-row', {
    staticClass: "row--large"
  }, _vm._l(_vm.banner.slides.filter(function (slide) {
    return slide.image;
  }), function (slide, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "href": slide.url
      }
    }, [_c('div', {
      staticClass: "banner__img",
      style: 'background-image:url(' + slide.image + ');'
    })]), slide.title ? _c('div', {
      staticClass: "text-center mt-10 mt-sm-14 mt-lg-26"
    }, [_c('span', {
      staticClass: "font-size-16 font-size-sm-18 font-size-lg-24 font-weight-medium grey-16--text"
    }, [_vm._v(_vm._s(slide.title))])]) : _vm._e()], 1);
  }), 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }