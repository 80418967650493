var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center",
      "justify": "end",
      "justify-xl": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "content-class": "menu-arrow"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-btn--none-active-bg pa-0",
          attrs: {
            "x-small": "",
            "fab": "",
            "color": "transparent"
          }
        }, 'v-btn', attrs, false), on), [_c('i', {
          staticClass: "icon icon-share-dark"
        })])];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    attrs: {
      "href": "https://www.youtube.com/user/dentiumworld",
      "target": "_blank"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-youtube"
  })]), _c('v-col', [_c('div', {
    staticClass: "my-n2"
  }, [_c('v-list-item-title', [_vm._v("Youtube")]), _c('div', {
    staticClass: "font-size-14 grey-90--text"
  }, [_vm._v("View More")])], 1)])], 1)], 1), _c('v-list-item', {
    attrs: {
      "href": "https://brightz.co.kr/",
      "target": "_blank"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('i', {
    staticClass: "icon icon-bright"
  })]), _c('v-col', [_c('div', {
    staticClass: "my-n2"
  }, [_c('v-list-item-title', [_vm._v("bright Implant")]), _c('div', {
    staticClass: "font-size-14 grey-90--text"
  }, [_vm._v("View More")])], 1)])], 1)], 1), _c('v-list-item', {
    attrs: {
      "href": "http://www.clinicaldentium.com",
      "target": "_blank"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-tooth-outline")])], 1), _c('v-col', [_c('div', {
    staticClass: "my-n2"
  }, [_c('v-list-item-title', [_vm._v("Clinical Dentium")]), _c('div', {
    staticClass: "font-size-14 grey-90--text"
  }, [_vm._v("View More")])], 1)])], 1)], 1)], 1)], 1)], 1), _vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg pa-0",
    attrs: {
      "x-small": "",
      "fab": "",
      "color": "transparent",
      "to": "/mypage/myinfo"
    }
  }, [_c('i', {
    staticClass: "icon icon-mypage-dark"
  })])], 1) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('gnb-login-button')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }