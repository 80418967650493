<template>
    <v-row class="row--x-small">
        <!-- <v-col cols="12" md="auto">
            <v-select outlined hide-details />
        </v-col> -->
        <v-col>
            <u-text-field v-model="searchValue" placeholder="Please enter a search term." @search="search" />
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn block color="grey-6" class="v-btn--input" @click="search">Search</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import UTextField from "../dumb/u-text-field.vue";

export default {
    components: { UTextField },
    data: () => ({
        searchValue: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        "$route.query.searchValue"() {
            this.init();
        },
    },
    methods: {
        init() {
            this.searchValue = this.$route.query.searchValue || null;
        },
        search() {
            let path = "/search";
            let { searchValue } = this;
            let query = { searchValue };
            this.$emit("close");
            this.$router.push({ path, query });
        },
    },
};
</script>

<style></style>
