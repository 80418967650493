import axios from "@/plugins/axios";

import contents from "./contents";

let url = "/api/v1/sns/accounts";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },

    contents,
};
