var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "main-visual"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.banner.slides.filter(function (slide) {
    return slide.image;
  }), function (slide, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? null : slide === null || slide === void 0 ? void 0 : slide.url,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? slide === null || slide === void 0 ? void 0 : slide.url : null,
        "target": slide === null || slide === void 0 ? void 0 : slide.target
      }
    }, [_c('div', {
      staticClass: "main-visual__img",
      style: 'background-image:url(' + slide.image + ');'
    })])], 1);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })])], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }