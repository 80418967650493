<template>
    <div class="side-gnb-wrap">
        <div v-if="accessToken" class="side-gnb-wrap__right">
            <v-btn x-small outlined color="grey-e6" @click.native="navigate"><span>Logout</span></v-btn>
        </div>

        <v-container>
            <ul class="slide-nav-1dep">
                <template v-for="item in gnb">
                    <!-- has no children -->
                    <li v-if="!item.children" :key="item.title" class="slide-nav-1dep_li">
                        <a v-if="item.href" :href="item.href" target="_blank" class="slide-nav__link">
                            <h2>{{ item.title }}</h2> </a
                        ><router-link v-else :to="item.to" class="slide-nav__link">
                            <h2>{{ item.title }}</h2>
                        </router-link>
                    </li>

                    <!-- has children -->
                    <li v-else :key="item.title" class="slide-nav-1dep_li">
                        <a v-if="item.href" :href="item.href" target="_blank" class="slide-nav__link">
                            <h2>{{ item.title }}</h2>
                        </a>
                        <router-link v-else :to="item.to" class="slide-nav__link">
                            <h2>{{ item.title }}</h2>
                        </router-link>

                        <div class="slide-nav-2dep">
                            <v-expansion-panels>
                                <template v-for="child in item.children">
                                    <div v-if="!child.children" :key="child.title" class="slide-nav-2dep__li">
                                        <a v-if="child.href" :href="child.href" target="_blank" class="slide-nav__link slide-nav-2dep__link">{{ child.title }}</a>
                                        <router-link v-else :to="child.to" class="slide-nav__link slide-nav-2dep__link">{{ child.title }}</router-link>
                                    </div>

                                    <v-expansion-panel v-else :key="child.title" class="slide-nav-2dep__li">
                                        <v-expansion-panel-header expand-icon="mdi-plus">
                                            <a v-if="child.href" :href="child.href" target="_blank" class="slide-nav__link slide-nav-2dep__link">
                                                <h2>{{ child.title }}</h2>
                                            </a>
                                            <router-link v-else :to="child.to" class="slide-nav__link slide-nav-2dep__link">
                                                <h2>{{ child.title }}</h2>
                                            </router-link>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <ul class="slide-nav-3dep">
                                                <template v-for="grandChild in child.children">
                                                    <li v-if="!grandChild.children" :key="grandChild.title" class="slide-nav-3dep__li" @click="pcMenuHide">
                                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" class="slide-nav__link slide-nav-3dep__link">{{ grandChild.title }}</a>
                                                        <router-link v-else :to="grandChild.to" class="slide-nav__link slide-nav-3dep__link">{{ grandChild.title }}</router-link>
                                                    </li>
                                                </template>
                                            </ul>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </template>
                            </v-expansion-panels>
                        </div>
                    </li>
                </template>
            </ul>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        className: String,
        indexNum: String,
    },
    data: function() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    computed: {
        ...mapGetters("ui", ["gnb"]),
        accessToken() {
            return this.$store.state.accessToken;
        },
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.init();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        ...mapActions("ui", ["getCategories"]),
        navigate() {
            if (this.accessToken) {
                this.$store.dispatch("logout");
                window.location.reload(true);
            } else {
                this.$router.push(`/login`);
            }
        },
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        pcMenuHide: function() {
            var HTML = document.querySelector("html");
            HTML.classList.remove("pc-menu-on");
        },
        async init() {
            this.getCategories();
            this.pcMenuHide();
        },
    },
};
</script>

<style lang="scss" scoped>
.side-gnb-wrap {
    &__right {
        position: fixed;
        top: calc(var(--header-body) + 30px);
        left: 50%;
        -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
        max-width: calc(var(--container-lg) + 12px * 2);
        width: 100%;
        text-align: right;
        padding: 0 12px;
    }
}
</style>
