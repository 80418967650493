import axios from "@/plugins/axios";

let url = "/api/v1/me";

import academyCoupons from "./academy-coupons";
import academyFavorites from "./academy-favorites";
import academyOrders from "./academy-orders";
import academyPurchases from "./academy-purchases";
import bookmarks from "./bookmarks";
import inquires from "./inquires";
import lectures from "./lectures";
import likes from "./likes";
import points from "./points";

export default {
    academyCoupons,
    academyFavorites,
    academyOrders,
    academyPurchases,
    bookmarks,
    inquires,
    lectures,
    likes,
    points,

    get() {
        return axios.get(url).then((result) => result.data);
    },
    put(data) {
        return axios.put(url, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
};
